<template>
  <section class="mt-6">
    <template v-if="clicouNoPersonalizado">
      <v-card class="my-2 py-4 text-center teal darken-4">
        <h2 class="grey--text text--lighten-4">{{ title }}</h2>
      </v-card>
      <v-col
        cols="12"
        class="form-group"
        v-for="index in quantidadeFolhasEditorPersonalizado"
        :key="index"
      >
        <h2>Folha {{ index }}</h2>
        <v-btn plain small @click="removeFolha(index)" class="pl-0">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <vue-editor v-model="conteudoEditorPersonalizado[index - 1]" />
      </v-col>

      <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
    </template>
    <template v-else>
      <v-card>
        <v-card class="my-2 py-4 text-center teal darken-4">
          <h2 class="grey--text text--lighten-4">{{ title }}</h2>
        </v-card>
        <v-alert
          v-if="!!laudos[0].folhas"
          outlined
          type="warning"
          border="left"
          text=""
        >
          Esse <strong>laudo</strong> teve a impressão
          <strong>personalizada.</strong>
          <v-btn text @click="adcionarConteudoEditorPersonalizado"
            >Clique aqui para visualizar</v-btn
          >
        </v-alert>

        <v-tabs class="pa-4">
          <v-tab v-for="(laudo, index) in laudos" :key="index">
            Laudo {{ index + 1 }}
          </v-tab>

          <v-tab-item v-for="(laudo, index) in laudos" :key="index">
            <v-row class="pt-5" no-gutters>
              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              ><h4>História materna</h4>
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >Data de nascimento 
              <v-text-field
                  type="date"
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  @change="calcularIdadeEMeses"
                  v-model="laudo.dataNascimento"
                  :disabled="!camposExibidos[index].exDataNascimento"
                />
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0 && laudo.dataNascimento"
              >
            <span>
              {{ laudo.anos }} {{ laudo.anos>1 ? ' anos ': 'ano'}} e {{ laudo.meses }} {{ laudo.meses>1?' meses ':'mês' }} 
            </span>
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >Peso
              <v-text-field
                  
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  @change="calculaImc"
                 
                  v-model="laudo.peso"
                  
                />
                <span>kg. Altura</span>
                <v-text-field
                  
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  @change="calculaImc"
                 
                  v-model="laudo.altura"
                  
                />
                <span>cm. &nbsp; </span>
                <span>IMC: {{ laudo.imc }}</span>
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Origem racial: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Branco',
                    'Preto',
                    'Sul da Ásia',
                    'Leste Asiático',
                    'Pardo'
                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.origemRacial"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Método de concepção: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Espontâneo',
                    'Medicamento para ovulação',
                    'Fertilização in vitro'

                  ]"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.metodoConcepcao"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Fumar durante a gravidez: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.fuma"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Hipertensão crônica: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.hipertensao"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Diabetes: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.diabetes"
                />
                <span v-if="laudo.diabetes == 'Sim'">. Tipo: </span>
              <v-select
              v-if="laudo.diabetes == 'Sim'"
                  class="mx-2 flex-grow-0"
                  :items="[
                    'gestacional',
                    'Tipo I',
                    'Tipo II'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.tipoDiabetes"
                />
            </v-col>
            <!-- <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Diabetes tipo I: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.diabeteTipoUm"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Diabetes tipo II: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.diabeteTipoDois"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Diabetes gestacional: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.diabeteTipoGestacional"
                />
            
            </v-col> -->
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Lúpus eritematoso sistêmico: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.lupus"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Síndrome antifosfolipídica: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.sindromeAnti"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>História familiar de diabetes </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    '1º grau',
                    '2º grau',
                    '3º grau',
                    'Não',

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.historicoFamiliarDiabetes"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>História obstétrica anterior: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Nulípara',
                    'Nenhuma gravidez anterior ≥16 semanas ',
                    'Gestações anteriores apenas com partos iatrogênicos <37 semanas',
                    'Parto espontâneo apenas entre 16 e 30 semanas (um evento) ',
                    'Parto espontâneo apenas entre 16 e 30 semanas (dois eventos)',
                    'Parto espontâneo entre 16 e 30 semanas (um evento) mais parto espontâneo em 31 a 36 semanas',
                    'Parto espontâneo entre 16-30 semanas (um evento) mais entrega ≥ 37 semanas',
                    'Parto espontâneo entre 16-30 semanas (dois eventos) mais parto ≥ 37 semanas',
                    'Parto espontâneo apenas em 31-36 semanas',
                    'Parto espontâneo apenas ≥37 semanas',

                  ]"
                  multiple
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.historiaObstetricaAnterior"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Perdas gestacionais: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Nulípara, aborto anterior <16 semanas',
                    'Nulípara, aborto anterior entre 16 e 23 semanas',
                    'Paridade sem natimortos anteriores ≥24 semanas',
                    'Paridade com natimortos anteriores ≥24 semanas',
                   

                  ]"
                  multiple
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.perdasGestacionais"
                />
            
            </v-col>
            
              
              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
                <span
                  :class="
                    !camposExibidos[index].exibeIndicacaoExame
                      ? 'text--disabled'
                      : ''
                  "
                  >Indicação do exame</span
                >

                <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'rastreamento de cromossomopatia e pré-eclâmpsia',
                    'rastreamento de cromossomopatia'
                  ]"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeIndicacaoExame"
                  placeholder="rastreamento de cromossomopatia"
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.indicacaoExame"
                />


                <!-- <v-text-field
                  class="mx-2 flex-grow-0"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeIndicacaoExame"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.indicacaoExame"
                /> -->
                <span
                  :class="
                    !camposExibidos[index].exibeIndicacaoExame
                      ? 'text--disabled'
                      : ''
                  "
                  >.</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeIndicacaoExame"
                  @click="
                    camposExibidos[index].exibeIndicacaoExame = true;
                    infosCompartilhadas.indicacaoExame = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeIndicacaoExame"
                  @click="
                    camposExibidos[index].exibeIndicacaoExame = false;
                    infosCompartilhadas.indicacaoExame = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
                <span
                  :class="
                    !camposExibidos[index].exibeTransdutor
                      ? 'text--disabled'
                      : ''
                  "
                  >Exame realizado com transdutor</span
                >
                <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'convexo',
                    'endocavitário',
                    'convexo e endocavitário',
                  ]"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeTransdutor"
                  placeholder="convexo"
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="infosCompartilhadas.transdutor"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeTransdutor
                      ? 'text--disabled'
                      : ''
                  "
                  >de alta resolução.</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeTransdutor"
                  @click="camposExibidos[index].exibeTransdutor = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeTransdutor"
                  @click="
                    camposExibidos[index].exibeTransdutor = false;
                    infosCompartilhadas.transdutor = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
                :class="
                  !camposExibidos[index].exibeDataMensUm ? 'text--disabled' : ''
                "
              >
                <span>Data da última menstruação</span>
                <v-text-field
                  type="date"
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  v-model="infosCompartilhadas.dataUltimaMestrucao"
                  :disabled="!camposExibidos[index].exibeDataMensUm"
                />
                <span>.</span>
                <v-checkbox
                  label="incerta"
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  dense
                  v-model="laudo.incerta"
                  :disabled="!camposExibidos[index].exibeDataMens"
                />
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDataMensUm"
                  @click="
                    camposExibidos[index].exibeDataMensUm = true;
                    infosCompartilhadas.dataUltimaMestrucao = undefined;
                    laudo.incerta = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeDataMensUm"
                  @click="
                    camposExibidos[index].exibeDataMensUm = false;
                    infosCompartilhadas.dataUltimaMestrucao = undefined;
                    laudo.incerta = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                v-if="index == 0"
                cols="12"
                class="d-flex justify-start align-center"
              >
                <span
                  :class="
                    !camposExibidos[index].exibeSemMens ? 'text--disabled' : ''
                  "
                  >Idade gestacional (DUM)</span
                >
                <v-text-field
                  class="mx-2 flex-grow-0"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :disabled="!camposExibidos[index].exibeSemMens"
                  :value="infosCompartilhadas.semanasDUM"
                  v-model="infosCompartilhadas.semanasDUM"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeSemMens ? 'text--disabled' : ''
                  "
                  >semanas e</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeSemMens"
                  @click="
                    camposExibidos[index].exibeSemMens = true;
                    infosCompartilhadas.semanasDUM = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeSemMens"
                  @click="
                    camposExibidos[index].exibeSemMens = false;
                    infosCompartilhadas.semanasDUM = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <v-text-field
                  class="mx-2 flex-grow-0"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :disabled="!camposExibidos[index].exibeDiasDum"
                  :value="infosCompartilhadas.diasDUM"
                  v-model="infosCompartilhadas.diasDUM"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeDiasDum ? 'text--disabled' : ''
                  "
                  >dias.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDiasDum"
                  @click="
                    camposExibidos[index].exibeDiasDum = true;
                    infosCompartilhadas.diasDUM = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeDiasDum"
                  @click="
                    camposExibidos[index].exibeDiasDum = false;
                    infosCompartilhadas.diasDUM = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
<h3>Ultrassonografia</h3>
              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
                :class="
                  !camposExibidos[index].exibeGest ? 'text--disabled' : ''
                "
              >
                <span>Gestação</span>
                <v-select
                  :items="tipoGemeo"
                  validate-on-blur
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :disable="podeEditar"
                  dense
                  class="flex-grow-0 mx-2"
                  :disabled="!camposExibidos[index].exibeGest"
                  v-model="infosCompartilhadas.gestacao"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeGest"
                  @click="camposExibidos[index].exibeGest = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeGest"
                  @click="camposExibidos[index].exibeGest = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibePlacenta ? 'text--disabled' : ''
                "
              >
                <span>Placenta</span>
                <v-select
                  class="mx-2 flex-grow-0"
                  :class="
                    !camposExibidos[index].exibePlacenta ? 'text--disabled' : ''
                  "
                  :items="[
                    'próxima ao colo uterino',
                    'distante do colo uterino',
                  ]"
                  validate-on-blur
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  dense
                  :disabled="!camposExibidos[index].exibePlacenta"
                  v-model="laudo.placenta"
                  placeholder="próxima ao colo uterino"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibePlacenta"
                  @click="camposExibidos[index].exibePlacenta = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibePlacenta"
                  @click="
                    camposExibidos[index].exibePlacenta = false;
                    laudo.placenta = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>


              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].freqCardFet ? 'text--disabled' : ''
                "
              >
                <span>Frequência cardíaca fetal:</span>
                <v-text-field
                  :disabled="!camposExibidos[index].freqCardFet"
                  class="mx-2 flex-grow-0"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.frequenciaCardiacaFetal"
                />
                <span>bpm.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].freqCardFet"
                  @click="camposExibidos[index].freqCardFet = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].freqCardFet"
                  @click="
                    camposExibidos[index].freqCardFet = false;
                    laudo.frequenciaCardiacaFetal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              
              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].compNadegas ? 'text--disabled' : ''
                "
              >
                <span>Comprimento cabeça-nádegas:</span>
                <v-text-field
                  class="mx-2 flex-grow-0"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].compNadegas"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.comprimentoCabecaNadegas"
                />
                <span>mm.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].compNadegas"
                  @click="camposExibidos[index].compNadegas = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].compNadegas"
                  @click="
                    camposExibidos[index].compNadegas = false;
                    laudo.comprimentoCabecaNadegas = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="infosCompartilhadas.gestacao > 1"
              >
                <h3>Corionicidade</h3></v-col
              >

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exNumPlaq ? 'text--disabled' : ''
                "
                v-if="infosCompartilhadas.gestacao > 1"
              >
                <span>Número de Placentas:</span><br />
                <v-text-field
                  :disabled="!camposExibidos[index].exNumPlaq"
                  class="mx-2 flex-grow-0"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.numPlacentas"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exNumPlaq"
                  @click="camposExibidos[index].exNumPlaq = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exNumPlaq"
                  @click="
                    camposExibidos[index].exNumPlaq = false;
                    laudo.numPlacentas = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="!camposExibidos[index].exNumBol ? 'text--disabled' : ''"
                v-if="infosCompartilhadas.gestacao > 1"
              >
                <span>Número de bolsas amnióticas:</span>
                <v-text-field
                  :disabled="!camposExibidos[index].exNumBol"
                  class="mx-2 flex-grow-0"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.numBolsasAmni"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exNumBol"
                  @click="camposExibidos[index].exNumBol = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exNumBol"
                  @click="
                    camposExibidos[index].exNumBol = false;
                    laudo.numBolsasAmni = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" v-if="morfologicaPrimeiroTrimestre">
                <v-row class="pt-1" no-gutters>
                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center pb-2"
                    :class="
                      !camposExibidos[index].exDiamBi ? 'text--disabled' : ''
                    "
                  >
                    <span>Diâmetro biparietal:</span>
                    <v-text-field
                      :disabled="!camposExibidos[index].exDiamBi"
                      v-mask="''"
                      class="mx-2 flex-grow-0"
                      dense
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      required
                      validate-on-blur
                      v-model="laudo.diametroBiparietalPrimeiroTrimestre"
                    ></v-text-field>
                    <span>mm.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exDiamBi"
                      @click="
                        camposExibidos[index].exDiamBi = true;
                        laudo.diametroBiparietalPrimeiroTrimestre = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exDiamBi"
                      @click="
                        camposExibidos[index].exDiamBi = false;
                        laudo.diametroBiparietalPrimeiroTrimestre = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeCircCef
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Circunferência cefálica:</span>
                    <v-text-field
                      v-mask="''"
                      class="mx-2 flex-grow-0"
                      dense
                      :disabled="!camposExibidos[index].exibeCircCef"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      required
                      validate-on-blur
                      v-model="laudo.circunferenciaCefalicaPrimeiroTrimestre"
                    />
                    <span>mm.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeCircCef"
                      @click="
                        camposExibidos[index].exibeCircCef = true;
                        laudo.circunferenciaCefalicaPrimeiroTrimestre =
                          undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeCircCef"
                      @click="
                        camposExibidos[index].exibeCircCef = false;
                        laudo.circunferenciaCefalicaPrimeiroTrimestre =
                          undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeCircAbd
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Circunferência abdominal:</span>
                    <v-text-field
                      v-mask="''"
                      class="mx-2 flex-grow-0"
                      dense
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      :disabled="!camposExibidos[index].exibeCircAbd"
                      required
                      validate-on-blur
                      v-model="laudo.circunferenciaAbdominalPrimeiroTrimestre"
                    ></v-text-field>
                    <span>mm</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeCircAbd"
                      @click="
                        camposExibidos[index].exibeCircAbd = true;
                        laudo.circunferenciaAbdominalPrimeiroTrimestre =
                          undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeCircAbd"
                      @click="
                        camposExibidos[index].exibeCircAbd = false;
                        laudo.circunferenciaAbdominalPrimeiroTrimestre =
                          undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeFemur ? 'text--disabled' : ''
                    "
                  >
                    <span>Fêmur:</span>
                    <v-text-field
                      v-mask="''"
                      class="mx-2 flex-grow-0"
                      dense
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      :disabled="!camposExibidos[index].exibeFemur"
                      required
                      validate-on-blur
                      v-model="laudo.femurPrimeiroTrimestre"
                    />
                    <span>mm.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeFemur"
                      @click="
                        camposExibidos[index].exibeFemur = true;
                        laudo.femurPrimeiroTrimestre = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeFemur"
                      @click="
                        camposExibidos[index].exibeFemur = false;
                        laudo.femurPrimeiroTrimestre = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibePesoPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Peso:</span>
                    <v-text-field
                      v-mask="''"
                      class="mx-2 flex-grow-0"
                      dense
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      required
                      :disabled="!camposExibidos[index].exibePesoPriTri"
                      validate-on-blur
                      v-model="laudo.pesoPrimeiroTrimestre"
                    ></v-text-field>
                    <span>g.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibePesoPriTri"
                      @click="
                        camposExibidos[index].exibePesoPriTri = true;
                        laudo.pesoPrimeiroTrimestre = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibePesoPriTri"
                      @click="
                        camposExibidos[index].exibePesoPriTri = false;
                        laudo.pesoPrimeiroTrimestre = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <h3>Morfologia fetal</h3>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeCranPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Crânio</span>
                    <v-select
                      :items="[
                        'ossos da calota craniana presentes',
                        'ossos da calota craniana não identificados, com exteriorização do parênquima cerebral, compatível com sequência acrânia/exencefalia/anencefalia',
                        'avaliação limitada',
                        'outros'
                      ]"
                      class="mx-2 flex-grow-0"
                      dense
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      :disabled="!camposExibidos[index].exibeCranPriTri"
                      required
                      validate-on-blur
                      v-model="laudo.cranioPrimeiroTrimestre"
                    ></v-select>
                    <v-textarea v-if="laudo.cranioPrimeiroTrimestre == 'outros'" 
                    required 
                    validate-on-blur
                    class="mx-2 flex-grow-0" 
                    dense 
                    filled 
                    outlined 
                    no-resize 
                    label="Comentários adicionais"
                    v-model="laudo.comentariosAdcionaisCranio"
                    :readonly="carregamentoDoLaudo || !podeEditar">
                    </v-textarea>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeCranPriTri"
                      @click="
                        camposExibidos[index].exibeCranPriTri = true;
                        laudo.cranioPrimeiroTrimestre = undefined;
                        laudoSelecionado.comentariosAdcionaisCranio = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeCranPriTri"
                      @click="
                        camposExibidos[index].exibeCranPriTri = false;
                        laudo.cranioPrimeiroTrimestre = undefined;
                        laudoSelecionado.comentariosAdcionaisCranio = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeSisNervPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Sistema nervoso central</span>
                    <v-select
                      :items="[
                        'sem alterações visíveis nessa idade gestacional',
                        'encéfalo desorganizado',
                        'avaliação limitada',
                        'coluna sem alterações visíveis nessa idade gestacional',
                        'desvio do eixo',
                        'falha de fechamento',
                        'avaliação limitada',
                        'outros'
                      ]"
                      class="mx-2 flex-grow-0"
                      dense
                      required
                      :disabled="!camposExibidos[index].exibeSisNervPriTri"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.sistemaNervosoCentralPrimeiroTrimistre"
                    ></v-select>
                    <v-textarea v-if="laudo.sistemaNervosoCentralPrimeiroTrimistre == 'outros'" 
                    required 
                    validate-on-blur
                    class="mx-2 flex-grow-0" 
                    dense 
                    filled 
                    outlined 
                    no-resize 
                    label="Comentários adicionais"
                    v-model="laudo.sistemaNervosoCentralPrimeiroTrimistreOutros"
                    :readonly="carregamentoDoLaudo || !podeEditar">
                    </v-textarea>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeSisNervPriTri"
                      @click="
                        camposExibidos[index].exibeSisNervPriTri = true;
                        laudo.sistemaNervosoCentralPrimeiroTrimistre =
                          undefined;
                        laudo.sistemaNervosoCentralPrimeiroTrimistreOutros = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeSisNervPriTri"
                      @click="
                        camposExibidos[index].exibeSisNervPriTri = false;
                        laudo.sistemaNervosoCentralPrimeiroTrimistre =
                          undefined;
                        laudo.sistemaNervosoCentralPrimeiroTrimistreOutros =
                          undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeTransIntraPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Translucência intracraniana</span>
                    <v-select
                      :items="[
                        'presente medindo',
                        'obliterada',
                        'avaliação limitada',
                      ]"
                      class="mx-2 flex-grow-0"
                      required
                      :disabled="!camposExibidos[index].exibeTransIntraPriTri"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      dense
                      validate-on-blur
                      v-model="
                        laudo.translucenciaInstracranianaPrimeiroTrimistre
                      "
                    />
                    <v-text-field
                      v-if="
                        laudo.translucenciaInstracranianaPrimeiroTrimistre ==
                        'presente medindo'
                      "
                      class="mx-2 flex-grow-0"
                      v-mask="''"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      dense
                      validate-on-blur
                      v-model="
                        laudo.translucenciaInstracranianaPrimeiroTrimistreMedindo
                      "
                    />

                    <span
                      v-if="
                        laudo.translucenciaInstracranianaPrimeiroTrimistre ==
                        'presente medindo'
                      "
                      >mm.
                    </span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeTransIntraPriTri"
                      @click="
                        camposExibidos[index].exibeTransIntraPriTri = true;
                        laudo.translucenciaInstracranianaPrimeiroTrimistreMedindo =
                          undefined;
                        laudo.translucenciaInstracranianaPrimeiroTrimistre =
                          undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeTransIntraPriTri"
                      @click="
                        camposExibidos[index].exibeTransIntraPriTri = false;
                        laudo.translucenciaInstracranianaPrimeiroTrimistreMedindo =
                          undefined;
                        laudo.translucenciaInstracranianaPrimeiroTrimistre =
                          undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeOrbPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Órbitas</span>
                    <v-select
                      :items="[
                        'presentes',
                        'não visualizadas',
                        'avaliação limitada',
                      ]"
                      required
                      class="mx-2 flex-grow-0"
                      dense
                      :disabled="!camposExibidos[index].exibeOrbPriTri"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.orbitasPrimeiroTrimistre"
                    ></v-select>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeOrbPriTri"
                      @click="
                        camposExibidos[index].exibeOrbPriTri = true;
                        laudo.orbitasPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeOrbPriTri"
                      @click="
                        camposExibidos[index].exibeOrbPriTri = false;
                        laudo.orbitasPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibePerFetalPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Perfil fetal</span>
                    <v-select
                      :items="[
                        'aparentemante normal',
                        'atípico',
                        'avaliação limitada',
                        'Osso nasal ausente/hipoplásico',
                        'avaliação limitada',
                        'quatro câmaras normal',
                        'avaliação limitada',
                        'outros'
                      ]"
                      class="mx-2 flex-grow-0"
                      dense
                      required
                      :disabled="!camposExibidos[index].exibePerFetalPriTri"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.perfilFetalPrimeiroTrimistre"
                    />
                    <v-textarea v-if="laudo.perfilFetalPrimeiroTrimistre == 'outros'" 
                    required 
                    validate-on-blur
                    class="mx-2 flex-grow-0" 
                    dense 
                    filled 
                    outlined 
                    no-resize 
                    label="Comentários adicionais"
                    v-model="laudo.perfilFetalPrimeiroTrimistreOutros"
                    :readonly="carregamentoDoLaudo || !podeEditar">
                    </v-textarea>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibePerFetalPriTri"
                      @click="
                        camposExibidos[index].exibePerFetalPriTri = true;
                        laudo.perfilFetalPrimeiroTrimistre = undefined;
                        laudo.perfilFetalPrimeiroTrimistreOutros = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibePerFetalPriTri"
                      @click="
                        camposExibidos[index].exibePerFetalPriTri = false;
                        laudo.perfilFetalPrimeiroTrimistre = undefined;
                        laudo.perfilFetalPrimeiroTrimistreOutros = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>
                  

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeEstPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Estômago</span>
                    <v-select
                      :items="[
                        'presente',
                        'não visualizado durante o exame',
                        'aumentado',
                        'pequeno',
                        'avaliação limitada',
                      ]"
                      class="mx-2 flex-grow-0"
                      dense
                      required
                      :disabled="!camposExibidos[index].exibeEstPriTri"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.estomagoPrimeiroTrimistre"
                    />
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeEstPriTri"
                      @click="
                        camposExibidos[index].exibeEstPriTri = true;
                        laudo.estomagoPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeEstPriTri"
                      @click="
                        camposExibidos[index].exibeEstPriTri = false;
                        laudo.estomagoPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeCor
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Coração</span>
                    <v-select
                      :items="[
                        'quatro câmaras habitual',
                        'quatro câmaras não habitual',
                        'avaliação limitada',
                      
                      ]"
                      class="mx-2 flex-grow-0"
                      dense
                      required
                      :disabled="!camposExibidos[index].exibeCor"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.coracaoCampo"
                    />
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeCor"
                      @click="
                        camposExibidos[index].exibeCor = true;
                        laudo.coracaoCampo = undefined;
                  
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeCor"
                      @click="
                        camposExibidos[index].exibeCor = false;
                        laudo.coracaoCampo = undefined;
                        
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeParAbdPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Parede abdominal</span>
                    <v-select
                      :items="[
                        'íntegra',
                        'com onfalocele',
                        'com gastrosquise',
                        'com defeito de fechamento',
                        'avaliação limitada',
                        'outros'
                      ]"
                      class="mx-2 flex-grow-0"
                      dense
                      :disabled="!camposExibidos[index].exibeParAbdPriTri"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.paredeAbdominalPrimeiroTrimistre"
                    ></v-select>
                    <v-textarea v-if="laudo.paredeAbdominalPrimeiroTrimistre == 'outros'" 
                    required 
                    validate-on-blur
                    class="mx-2 flex-grow-0" 
                    dense 
                    filled 
                    outlined 
                    no-resize 
                    label="Comentários adicionais"
                    v-model="laudo.paredeAbdominalPrimeiroTrimistreOutros"
                    :readonly="carregamentoDoLaudo || !podeEditar">
                    </v-textarea>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeParAbdPriTri"
                      @click="
                        camposExibidos[index].exibeParAbdPriTri = true;
                        laudo.paredeAbdominalPrimeiroTrimistre = undefined;
                        laudo.paredeAbdominalPrimeiroTrimistreOutros = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeParAbdPriTri"
                      @click="
                        camposExibidos[index].exibeParAbdPriTri = false;
                        laudo.paredeAbdominalPrimeiroTrimistre = undefined;
                        laudo.paredeAbdominalPrimeiroTrimistreOutros = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeBexPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Bexiga</span>
                    <v-select
                      :items="[
                        'presente',
                        'não visualizada durante o exame',
                        'aumentada',
                        'avaliação limitada',
                        'outros'
                      ]"
                      :disabled="!camposExibidos[index].exibeBexPriTri"
                      class="mx-2 flex-grow-0"
                      dense
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.bexigaPrimeiroTrimistre"
                    />
                    <v-textarea v-if="laudo.bexigaPrimeiroTrimistre == 'outros'" 
                    required 
                    validate-on-blur
                    class="mx-2 flex-grow-0" 
                    dense 
                    filled 
                    outlined 
                    no-resize 
                    label="Comentários adicionais"
                    v-model="laudo.bexigaPrimeiroTrimistreOutros"
                    :readonly="carregamentoDoLaudo || !podeEditar">
                    </v-textarea>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeBexPriTri"
                      @click="
                        camposExibidos[index].exibeBexPriTri = true;
                        laudo.bexigaPrimeiroTrimistre = undefined;
                        laudo.bexigaPrimeiroTrimistreOutros = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeBexPriTri"
                      @click="
                        camposExibidos[index].exibeBexPriTri = false;
                        laudo.bexigaPrimeiroTrimistre = undefined;
                        laudo.bexigaPrimeiroTrimistreOutros = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeCordUmbPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Cordão umbilical</span>
                    <v-select
                      :items="[
                        'três vasos e bem inserido no abdomen fetal',
                        'dois vasos e bem inserido no abdomen fetal',
                      ]"
                      class="mx-2 flex-grow-0"
                      dense
                      :disabled="!camposExibidos[index].exibeCordUmbPriTri"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.cordaoUmbilicalPrimeiroTrimistre"
                    />
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeCordUmbPriTri"
                      @click="
                        camposExibidos[index].exibeCordUmbPriTri = true;
                        laudo.cordaoUmbilicalPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeCordUmbPriTri"
                      @click="
                        camposExibidos[index].exibeCordUmbPriTri = false;
                        laudo.cordaoUmbilicalPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >

                    <span>,</span>

                    <v-select
                      :items="[
                        'inserido na porção central da placenta',
                        'inserido na periferia da placenta',
                      ]"
                      class="mx-2 flex-grow-0"
                      dense
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      required
                      validate-on-blur
                      v-model="laudo.cordaoUmbilicalInseridoNa"
                      :disabled="!camposExibidos[index].exibeCordUmbInsNa"
                    ></v-select>
                    <span>.</span>

                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeCordUmbInsNa"
                      @click="
                        camposExibidos[index].exibeCordUmbInsNa = true;
                        laudo.cordaoUmbilicalInseridoNa = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeCordUmbInsNa"
                      @click="
                        camposExibidos[index].exibeCordUmbInsNa = false;
                        laudo.cordaoUmbilicalInseridoNa = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeMembSupPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Membros superiores</span>
                    <v-select
                      :items="[
                        'dois membros visíveis',
                        'apenas o membro direito totalmente visível',
                        'apenas o membro esquerdo totalmente visível',
                        'avaliação limitada',
                      ]"
                      class="mx-2 flex-grow-0"
                      dense
                      :disabled="!camposExibidos[index].exibeMembSupPriTri"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.membrosSuperioresPrimeiroTrimistre"
                    ></v-select>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeMembSupPriTri"
                      @click="
                        camposExibidos[index].exibeMembSupPriTri = true;
                        laudo.membrosSuperioresPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeMembSupPriTri"
                      @click="
                        camposExibidos[index].exibeMembSupPriTri = false;
                        laudo.membrosSuperioresPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeMembInfPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Membros inferiores</span>
                    <v-select
                      :items="[
                        'dois membros visíveis',
                        'apenas o membro direito totalmente visível',
                        'apenas o membro esquerdo totalmente visível',
                        'avaliação limitada',
                      ]"
                      :disabled="!camposExibidos[index].exibeMembInfPriTri"
                      class="mx-2 flex-grow-0"
                      dense
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      required
                      validate-on-blur
                      v-model="laudo.membrosInferioresPrimeiroTrimistre"
                    />
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeMembInfPriTri"
                      @click="
                        camposExibidos[index].exibeMembInfPriTri = true;
                        laudo.membrosInferioresPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeMembInfPriTri"
                      @click="
                        camposExibidos[index].exibeMembInfPriTri = false;
                        laudo.membrosInferioresPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeArtRenInfPriTri
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Artérias renais</span>
                    <v-select
                      :items="[
                        'ambas visualizadas',
                        'ambas não visualizadas',
                        'apenas uma visualizada',
                        'de difícil visualização',
                        'avaliação limitada',
                      ]"
                      :disabled="!camposExibidos[index].exibeArtRenInfPriTri"
                      class="mx-2 flex-grow-0"
                      dense
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.artereiasRenaisPrimeiroTrimistre"
                    ></v-select>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeArtRenInfPriTri"
                      @click="
                        camposExibidos[index].exibeArtRenInfPriTri = true;
                        laudo.artereiasRenaisPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeArtRenInfPriTri"
                      @click="
                        camposExibidos[index].exibeArtRenInfPriTri = false;
                        laudo.artereiasRenaisPrimeiroTrimistre = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>

              <h3
                :class="
                  !camposExibidos[index].exibeTransNuc &&
                  !camposExibidos[index].exibeOssoNasal
                    ? 'text--disabled'
                    : ''
                "
              >
                Marcadores Para Cromossomopatias
              </h3>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeTransNuc ? 'text--disabled' : ''
                "
              >
                <span>Translucência nucal</span>
                <v-text-field
                  :disabled="!camposExibidos[index].exibeTransNuc"
                  class="mx-2 flex-grow-0"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.transulucenciaNucal"
                />
                <span>mm.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeTransNuc"
                  @click="camposExibidos[index].exibeTransNuc = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeTransNuc"
                  @click="
                    camposExibidos[index].exibeTransNuc = false;
                    laudo.transulucenciaNucal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeOssoNasal ? 'text--disabled' : ''
                "
              >
                <span>Osso nasal</span>

                <v-select
                  class="mx-2 flex-grow-0"
                  :items="['presente', 'ausente', 'de difícil avaliação']"
                  validate-on-blur
                  required
                  placeholder="presente"
                  :disabled="!camposExibidos[index].exibeOssoNasal"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  dense
                  v-model="laudo.ossoNasal"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeOssoNasal"
                  @click="camposExibidos[index].exibeOssoNasal = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeOssoNasal"
                  @click="
                    camposExibidos[index].exibeOssoNasal = false;
                    laudo.ossoNasal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                v-if="morfologicaPrimeiroTrimestre"
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeFluxoTri ? 'text--disabled' : ''
                "
              >
                <span>Fluxo Tricúspide</span>
                <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'normal',
                    'presença de regurgitação',
                    'de difícil avaliação',
                  ]"
                  :disabled="!camposExibidos[index].exibeFluxoTri"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.fluxoTriscupide"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeFluxoTri"
                  @click="
                    camposExibidos[index].exibeFluxoTri = true;
                    laudo.fluxoTriscupide = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeFluxoTri"
                  @click="
                    camposExibidos[index].exibeFluxoTri = false;
                    laudo.fluxoTriscupide = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                v-if="morfologicaPrimeiroTrimestre"
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeDucto ? 'text--disabled' : ''
                "
              >
                <span>Ducto venoso</span>
                <v-select
                  class="mx-2 flex-grow-0"
                  :items="['onda A normal', 'onda A reversa', 'de difícil avaliação ']"
                  validate-on-blur
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeDucto"
                  dense
                  v-model="laudo.ductoVenoso"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDucto"
                  @click="
                    camposExibidos[index].exibeDucto = true;
                    laudo.ductoVenoso = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeDucto"
                  @click="
                    camposExibidos[index].exibeDucto = false;
                    laudo.ductoVenoso = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <div
                class="d-flex flex-column"
                :class="
                  !camposExibidos[index].exibeRiscoTriDois &&
                  !camposExibidos[index].exibeRiscoTriUm
                    ? 'text--disabled'
                    : ''
                "
              >
                <h3>Risco Para Trissomias</h3>

                <span
                  class="mt-2"
                  :class="
                    !camposExibidos[index].exibeRiscoTriUm
                      ? 'text--disabled'
                      : ''
                  "
                  >Risco basal (baseado na idade materna):
                </span>
              </div>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeRiscoTriUm ? 'text--disabled' : ''
                "
              >
                <span>Trissomia do 21 - 1:</span>
                <v-text-field
                  :disabled="!camposExibidos[index].exibeRiscoTriUm"
                  class="mx-2 flex-grow-0"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.riscoTrissomiaum"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeRiscoTriUm"
                  @click="camposExibidos[index].exibeRiscoTriUm = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeRiscoTriUm"
                  @click="
                    camposExibidos[index].exibeRiscoTriUm = false;
                    laudo.riscoTrissomiaum = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <span
                :class="
                  !camposExibidos[index].exibeRiscoTriDois
                    ? 'text--disabled'
                    : ''
                "
                >Risco corrigido (baseado na idade materna, TN e FCP):</span
              >

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeRiscoTriDois
                    ? 'text--disabled'
                    : ''
                "
              >
                <span>Trissomia do 21 - 1:</span>
                <v-text-field
                  :disabled="!camposExibidos[index].exibeRiscoTriDois"
                  class="mx-2 flex-grow-0"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.riscoTrissomiadois"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeRiscoTriDois"
                  @click="camposExibidos[index].exibeRiscoTriDois = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeRiscoTriDois"
                  @click="
                    camposExibidos[index].exibeRiscoTriDois = false;
                    laudo.riscoTrissomiadois = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" v-if="preEclampsia">
                <h3>Risco para pré-eclâmpsia</h3>

                <v-row class="pt-2" no-gutters>
                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeArtUtDir
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Artéria uterina direita:</span>
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeArtUtDir
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>IP:</span>
                    <v-text-field
                      class="mx-2 flex-grow-0"
                      v-mask="''"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      dense
                      :disabled="!camposExibidos[index].exibeArtUtDir"
                      required
                      validate-on-blur
                      v-model="laudo.arteriaUterinaDireita"
                    ></v-text-field>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeArtUtDir"
                      @click="
                        camposExibidos[index].exibeArtUtDir = true;
                        laudo.arteriaUterinaDireita = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeArtUtDir"
                      @click="
                        camposExibidos[index].exibeArtUtDir = false;
                        laudo.arteriaUterinaDireita = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                    <span>.</span>
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeArtUtEsq
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Artéria uterina esquerda:</span>
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeArtUtEsq
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>IP:</span>
                    <v-text-field
                      v-mask="''"
                      class="mx-2 flex-grow-0"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      :disabled="!camposExibidos[index].exibeArtUtEsq"
                      required
                      dense
                      validate-on-blur
                      v-model="laudo.arteriaUterinaEsquerda"
                    ></v-text-field>
                    <span>.</span>

                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeArtUtEsq"
                      @click="
                        camposExibidos[index].exibeArtUtEsq = true;
                        laudo.arteriaUterinaEsquerda = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeArtUtEsq"
                      @click="
                        camposExibidos[index].exibeArtUtEsq = false;
                        laudo.arteriaUterinaEsquerda = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col cols="12" class="d-flex justify-start align-center">
                    <span
                      :class="
                        !camposExibidos[index].exibeMediaIP
                          ? 'text--disabled'
                          : ''
                      "
                      >Média do IP
                    </span>
                    <span
                      :class="
                        !camposExibidos[index].exibeMediaIP
                          ? 'text--disabled'
                          : ''
                      "
                    >
                      {{
                        ' : ' +
                        parseFloat(
                          (parseFloat(laudo.arteriaUterinaEsquerda) +
                            parseFloat(laudo.arteriaUterinaDireita)) /
                            2.0
                        ).toFixed(2)
                      }}
                    </span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeMediaIP"
                      @click="camposExibidos[index].exibeMediaIP = true"
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeMediaIP"
                      @click="camposExibidos[index].exibeMediaIP = false"
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >

                    <span
                      :class="
                        !camposExibidos[index].exibePercentil
                          ? 'text--disabled'
                          : ''
                      "
                      >{{ ' ( ' }}MoM
                    </span>
                    <v-text-field
                      v-mask="''"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      :disabled="!camposExibidos[index].exibePercentil"
                      dense
                      class="mx-2 flex-grow-0"
                      required
                      validate-on-blur
                      v-model="laudo.percentil"
                    ></v-text-field>
                    <span
                      :class="
                        !camposExibidos[index].exibePercentil
                          ? 'text--disabled'
                          : ''
                      "
                      >).</span
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibePercentil"
                      @click="
                        camposExibidos[index].exibePercentil = true;
                        laudo.percentil = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibePercentil"
                      @click="
                        camposExibidos[index].exibePercentil = false;
                        laudo.percentil = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeRiscBasal
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span>Risco para pré-eclâmpsia precoce - 1:</span>
                    <v-text-field
                      class="mx-2 flex-grow-0"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      dense
                      :disabled="!camposExibidos[index].exibeRiscBasal"
                      validate-on-blur
                      v-model="laudo.riscoBasalPreEclampsia"
                    ></v-text-field>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeRiscBasal"
                      @click="
                        camposExibidos[index].exibeRiscBasal = true;
                        laudo.riscoBasalPreEclampsia = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeRiscBasal"
                      @click="
                        camposExibidos[index].exibeRiscBasal = false;
                        laudo.riscoBasalPreEclampsia = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeRiscCorrigido
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span> Risco para pré-eclampsia tardia - 1: </span>
                    <v-text-field
                      class="mx-2 flex-grow-0"
                      dense
                      required
                      :disabled="!camposExibidos[index].exibeRiscCorrigido"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      validate-on-blur
                      v-model="laudo.riscoCorrigidoPreEclampsia"
                    ></v-text-field>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeRiscCorrigido"
                      @click="
                        camposExibidos[index].exibeRiscCorrigido = true;
                        laudo.riscoCorrigidoPreEclampsia = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeRiscCorrigido"
                      @click="
                        camposExibidos[index].exibeRiscCorrigido = false;
                        laudo.riscoCorrigidoPreEclampsia = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exibeRiscRestricao
                        ? 'text--disabled'
                        : ''
                    "
                  >
                    <span> Risco de restrição de crescimento fetal antes de 37 semana – 1:  </span>
                    <v-text-field
                      class="mx-2 flex-grow-0"
                      dense
                      required
                      :disabled="!camposExibidos[index].exibeRiscRestricao"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      validate-on-blur
                      v-model="laudo.riscoRestricaoCrescimento"
                    ></v-text-field>
                    <span>.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeRiscRestricao"
                      @click="
                        camposExibidos[index].exibeRiscRestricao = true;
                        laudo.riscoRestricaoCrescimento = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeRiscRestricao"
                      @click="
                        camposExibidos[index].exibeRiscRestricao = false;
                        laudo.riscoRestricaoCrescimento = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider />
              </v-col>

              <v-col cols="12" v-if="perfilCervical">
                <h3>Perfil Cervical</h3>

                <v-row class="pt-2" no-gutters>
                  <v-col cols="12" class="d-flex justify-start align-center">
                    <span
                      :class="
                        !camposExibidos[index].exCol ? 'text--disabled' : ''
                      "
                      >Colo uterino</span
                    >
                    <v-select
                      :items="['fechado', 'entreaberto']"
                      class="mx-2 flex-grow-0"
                      :disabled="!camposExibidos[index].exCol"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      dense
                      validate-on-blur
                      v-model="laudo.coloUterino"
                    />
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exCol"
                      @click="
                        camposExibidos[index].exCol = true;
                        laudo.coloUterino = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exCol"
                      @click="
                        camposExibidos[index].exCol = false;
                        laudo.coloUterino = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >

                    <span
                      :class="
                        !camposExibidos[index].exColRep ? 'text--disabled' : ''
                      "
                      >, com menor comprimento de </span
                    >
                    <v-text-field
                      :disabled="!camposExibidos[index].exColRep"
                      class="mx-2 flex-grow-0"
                      v-mask="''"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      dense
                      validate-on-blur
                      v-model="laudo.coloMedidaEmRepouco"
                    >
                    </v-text-field>
                    <span
                      :class="
                        !camposExibidos[index].exColRep ? 'text--disabled' : ''
                      "
                      >mm (colo avaliado por três minutos). </span
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exColRep"
                      @click="
                        camposExibidos[index].exColRep = true;
                        laudo.coloMedidaEmRepouco = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exColRep"
                      @click="
                        camposExibidos[index].exColRep = false;
                        laudo.coloMedidaEmRepouco = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <!-- <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exAvCol ? 'text--disabled' : ''
                    "
                  >
                    <span>Após três minutos de avaliação, o colo</span>
                    <v-select
                      class="mx-2 flex-grow-0"
                      :items="['passou a medir', 'continuou a medir']"
                      dense
                      required
                      :disabled="!camposExibidos[index].exAvCol"
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.coloPassouAMedir"
                    ></v-select>
                    <v-text-field
                      class="mx-2 flex-grow-0"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      :disabled="!camposExibidos[index].exAvCol"
                      dense
                      validate-on-blur
                      v-mask="''"
                      v-model="laudo.cm"
                    ></v-text-field>
                    <span>cm.</span>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exAvCol"
                      @click="
                        camposExibidos[index].exAvCol = true;
                        laudo.coloPassouAMedir = undefined;
                        laudo.cm = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exAvCol"
                      @click="
                        camposExibidos[index].exAvCol = false;
                        laudo.coloPassouAMedir = undefined;
                        laudo.cm = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col> -->

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exOrCerIn ? 'text--disabled' : ''
                    "
                  >
                    <span>Orifício cervical interno</span>
                    <v-select
                      :disabled="!camposExibidos[index].exOrCerIn"
                      class="mx-2 flex-grow-0"
                      :items="['fechado', 'aberto para']"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      dense
                      validate-on-blur
                      v-model="laudo.orificioCervicalInterno"
                    ></v-select>

                    <v-text-field
                      class="mx-2 flex-grow-0"
                      :disabled="!camposExibidos[index].exOrCerIn"
                      v-if="laudo.orificioCervicalInterno == 'aberto para'"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      dense
                      validate-on-blur
                      v-mask="''"
                      v-model="laudo.orificioCervicalInternoDois"
                    ></v-text-field>
                    <span v-if="laudo.orificioCervicalInterno == 'aberto para'">
                      mm
                    </span>
                    <span>.</span>

                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exOrCerIn"
                      @click="
                        camposExibidos[index].exOrCerIn = true;
                        laudo.orificioCervicalInternoDois = undefined;
                        laudo.orificioCervicalInterno = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exOrCerIn"
                      @click="
                        camposExibidos[index].exOrCerIn = false;
                        laudo.orificioCervicalInternoDois = undefined;
                        laudo.orificioCervicalInterno = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exProtBol ? 'text--disabled' : ''
                    "
                  >
                    <v-select
                      class="mx-2 flex-grow-0"
                      :items="['Ausência', 'Presença']"
                      :disabled="!camposExibidos[index].exProtBol"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      dense
                      validate-on-blur
                      v-model="laudo.protusaoBolsaAmniotica"
                    ></v-select>
                    <span
                      :class="
                        !camposExibidos[index].exProtBol ? 'text--disabled' : ''
                      "
                      >de protrusão da bolsa amniótica no momento do
                      exame.</span
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exProtBol"
                      @click="
                        camposExibidos[index].exProtBol = true;
                        laudo.protusaoBolsaAmniotica = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exProtBol"
                      @click="
                        camposExibidos[index].exProtBol = false;
                        laudo.protusaoBolsaAmniotica = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exSinalSlud ? 'text--disabled' : ''
                    "
                  >
                    <span>Sinal de sludge</span>
                    <v-select
                      class="mx-2 flex-grow-0"
                      :items="['ausente', 'presente']"
                      required
                      :disabled="!camposExibidos[index].exSinalSlud"
                      dense
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.sinalDeSludge"
                    ></v-select>
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exSinalSlud"
                      @click="
                        camposExibidos[index].exSinalSlud = true;
                        laudo.sinalDeSludge = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exSinalSlud"
                      @click="
                        camposExibidos[index].exSinalSlud = false;
                        laudo.sinalDeSludge = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                    <span>.</span>
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-start align-center"
                    :class="
                      !camposExibidos[index].exPolipo ? 'text--disabled' : ''
                    "
                  >
                    <v-select
                      class="mx-2 flex-grow-0"
                      :items="[
                        'Observamos pólipo endocervical medindo',
                        '-------------------------------------',
                      ]"
                      required
                      :disabled="!camposExibidos[index].exPolipo"
                      dense
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      validate-on-blur
                      v-model="laudo.polipoEndocervicalMedindo"
                    ></v-select>

                    <v-text-field
                      class="mx-2 flex-grow-0"
                      v-show="
                        laudo.polipoEndocervicalMedindo ==
                        'Observamos pólipo endocervical medindo'
                      "
                      required
                      dense
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      :disabled="!camposExibidos[index].exPolipo"
                      validate-on-blur
                      v-mask="''"
                      v-model="laudo.polipoEndocervicalMedindoCm"
                    ></v-text-field>

                    <span
                      v-show="
                        laudo.polipoEndocervicalMedindo ==
                        'Observamos pólipo endocervical medindo'
                      "
                    >
                      mm
                    </span>

                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exPolipo"
                      @click="
                        camposExibidos[index].exPolipo = true;
                        laudo.polipoEndocervicalMedindoCm = undefined;
                        laudo.polipoEndocervicalMedindo = undefined;
                      "
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exPolipo"
                      @click="
                        camposExibidos[index].exPolipo = false;
                        laudo.polipoEndocervicalMedindoCm = undefined;
                        laudo.polipoEndocervicalMedindo = undefined;
                      "
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                    <span>.</span>
                  </v-col>
                </v-row>
                <v-divider />
              </v-col>

              <h3>Conclusão</h3>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeGest ? 'text--disabled' : ''
                "
              >
                <span>Gestação</span>
                <v-select
                  :disabled="!camposExibidos[index].exibeGest"
                  class="mx-2 flex-grow-0"
                  :items="[
                    ...tipoGemeo,
                    {
                      text: 'Quíntuplo',
                      value: 5,
                    },
                  ]"
                  validate-on-blur
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :disable="!camposExibidos[index].exibeGest"
                  dense
                  v-model="infosCompartilhadas.gestacao"
                />

                <v-select
                  v-if="infosCompartilhadas.gestacao === 2"
                  class="mx-2 flex-grow-0"
                  :items="['dicoriônica', 'monocoriônica']"
                  dense
                  :disabled="!camposExibidos[index].exibeGestTres"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.gestacaoTres"
                />
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-show="infosCompartilhadas.gestacao === 2"
                  v-if="!camposExibidos[index].exibeGestTres"
                  @click="
                    camposExibidos[index].exibeGestTres = true;
                    laudo.gestacaoTres = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-show="infosCompartilhadas.gestacao === 2"
                  v-if="camposExibidos[index].exibeGestTres"
                  @click="
                    camposExibidos[index].exibeGestTres = false;
                    laudo.gestacaoTres = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <v-select
                  v-if="infosCompartilhadas.gestacao === 2"
                  class="mx-2 flex-grow-0"
                  :items="['monoamniótica', 'diamniótica']"
                  dense
                  :disabled="!camposExibidos[index].exibeGestQuatro"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.gestacaoQuatro"
                />
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-show="infosCompartilhadas.gestacao === 2"
                  v-if="!camposExibidos[index].exibeGestQuatro"
                  @click="
                    camposExibidos[index].exibeGestQuatro = true;
                    laudo.gestacaoQuatro = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-show="infosCompartilhadas.gestacao === 2"
                  v-if="camposExibidos[index].exibeGestQuatro"
                  @click="
                    camposExibidos[index].exibeGestQuatro = false;
                    laudo.gestacaoQuatro = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <v-textarea
                  v-if="[3, 4, 5].includes(infosCompartilhadas.gestacao)"
                  class="mx-2 flex-grow-0"
                  dense
                  rows="3"
                  :disabled="!camposExibidos[index].exibeGestCinco"
                  no-resize
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.gestacaoCinco"
                />
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-show="[3, 4, 5].includes(infosCompartilhadas.gestacao)"
                  v-if="!camposExibidos[index].exibeGestCinco"
                  @click="
                    camposExibidos[index].exibeGestCinco = true;
                    laudo.gestacaoCinco = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-show="[3, 4, 5].includes(infosCompartilhadas.gestacao)"
                  v-if="camposExibidos[index].exibeGestCinco"
                  @click="
                    camposExibidos[index].exibeGestCinco = false;
                    laudo.gestacaoCinco = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <span>com feto vivo.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeGest"
                  @click="camposExibidos[index].exibeGest = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeGest"
                  @click="camposExibidos[index].exibeGest = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exibeSemMens2 ? 'text--disabled' : ''
                  "
                  >Idade gestacional baseada na biometria fetal atual de</span
                >
                <v-text-field
                  :disabled="!camposExibidos[index].exibeSemMens2"
                  class="mx-2 flex-grow-0"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  v-model="laudo.semanasDUM2"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeSemMens2 ? 'text--disabled' : ''
                  "
                  >semanas e</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeSemMens2"
                  @click="
                    camposExibidos[index].exibeSemMens2 = true;
                    laudo.semanasDUM2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeSemMens2"
                  @click="
                    camposExibidos[index].exibeSemMens2 = false;
                    laudo.semanasDUM2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <v-text-field
                  :disabled="!camposExibidos[index].exibeDiasDum2"
                  class="mx-2 flex-grow-0"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  dense
                  v-model="laudo.diasDUM2"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeDiasDum2 ? 'text--disabled' : ''
                  "
                  >dias
                </span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDiasDum2"
                  @click="
                    camposExibidos[index].exibeDiasDum2 = true;
                    laudo.diasDUM2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeDiasDum2"
                  @click="
                    camposExibidos[index].exibeDiasDum2 = false;
                    laudo.diasDUM2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="
                    !camposExibidos[index].exibeDataMensDum ? 'text--disabled' : ''
                  "
                  >, baseada na ultrassonografia de primeiro trimestre 
                </span>
                <v-select
                  :disabled="!camposExibidos[index].exibeDataMensDum"
                  class="mx-2 flex-grow-0"
                  :items="['exame realizado no serviço', 'exame realizado em outro serviço']"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  
                  required
                  v-model="laudo.examServico"
                />

                
              </v-col>
              <v-col cols="12" class="d-flex justify-start align-center">
                <v-select
                  :disabled="!camposExibidos[index].exibeDataMensDum"
                  class="mx-2 flex-grow-0"
                  :items="['concordante', 'discordante ']"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                 
                  required
                  v-model="laudo.compativelIncompativel"
                />
                <span 
                  :class="
                    !camposExibidos[index].exibeDataMensDum
                      ? 'text--disabled'
                      : ''
                  "
                  >com a biometria atual.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDataMensDum"
                  @click="camposExibidos[index].exibeDataMensDum = true;
                  laudo.compativelIncompativel = undefined;
                    laudo.examServico = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeDataMensDum"
                  @click="
                    camposExibidos[index].exibeDataMensDum = false;
                    laudo.compativelIncompativel = undefined;
                    laudo.examServico = undefined;
                  "
                >
                  <v-icon>mdi-eye</v-icon></v-btn
                >

              </v-col>

              <v-col
                cols="12"
                class="pt-4 d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeDataParto ? 'text--disabled' : ''
                "
              >
                <span>Data provável do parto:</span>
                <v-text-field
                  :disabled="!camposExibidos[index].exibeDataParto"
                  type="date"
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  dense
                  v-model="laudo.dataProvavelParto"
                />
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDataParto"
                  @click="camposExibidos[index].exibeDataParto = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeDataParto"
                  @click="
                    camposExibidos[index].exibeDataParto = false;
                    laudo.dataProvavelParto = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <v-text
                  :class="
                    !camposExibidos[index].exibeRiscoTri ? 'text--disabled' : ''
                  "
                >
                  O risco de trissomia do 13, 18 e 21</v-text
                >

                <v-select
                  :class="
                    !camposExibidos[index].exibeRiscoTri ? 'text--disabled' : ''
                  "
                  :disabled="!camposExibidos[index].exibeRiscoTri"
                  class="mx-2 flex-grow-0"
                  :items="['aumentou', 'diminuiu']"
                  validate-on-blur
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  dense
                  v-model="laudo.riscoTrissomia"
                  placeholder="diminuiu"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeRiscoTri ? 'text--disabled' : ''
                  "
                  >após a medida da TN no feto avaliado, sendo considerado
                  de</span
                >
                <v-select
                  :class="
                    !camposExibidos[index].exibeRiscoTri ? 'text--disabled' : ''
                  "
                  :disabled="!camposExibidos[index].exibeRiscoTri"
                  class="mx-2 fl ex-grow-0 text--disabled"
                  :items="['baixo risco', 'risco intermediário', 'alto risco']"
                  validate-on-blur
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  placeholder="baixo risco"
                  dense
                  v-model="laudo.taxaRisco"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeRiscoTri ? 'text--disabled' : ''
                  "
                  >para cromossomopatias.
                </span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeRiscoTri"
                  @click="
                    camposExibidos[index].exibeRiscoTri = true;
                    laudo.riscoTrissomia = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeRiscoTri"
                  @click="
                    camposExibidos[index].exibeRiscoTri = false;
                    laudo.riscoTrissomia = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                v-if="morfologicaPrimeiroTrimestre"
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeMorfFetIdade
                    ? 'text--disabled'
                    : ''
                "
              >
                <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Morfologia fetal dentro do esperado para a idade gestacional',
                    'Alterações precoces da mofologia fetal, caracterizada por',
                  ]"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeMorfFetIdade"
                  required
                  dense
                  v-model="laudo.morfologiafetalIdadeGestacional"
                />

                <v-textarea
                  v-if="
                    laudo.morfologiafetalIdadeGestacional ==
                    'Alterações precoces da mofologia fetal, caracterizada por'
                  "
                  :disabled="!camposExibidos[index].exibeMorfFetIdade"
                  required
                  validate-on-blur
                  filled
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  outlined
                  no-resize
                  label="Comentários adicionais"
                  v-model="laudo.morfologiafetalIdadeGestacionaUmOutros"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeMorfFetIdade"
                  @click="
                    camposExibidos[index].exibeMorfFetIdade = true;
                    laudo.morfologiafetalIdadeGestacionaUmOutros = undefined;
                    laudo.morfologiafetalIdadeGestacional = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeMorfFetIdade"
                  @click="
                    camposExibidos[index].exibeMorfFetIdade = false;
                    laudo.morfologiafetalIdadeGestacionaUmOutros = undefined;
                    laudo.morfologiafetalIdadeGestacional = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <!-- <v-col
                v-if="preEclampsia && infosCompartilhadas.gestacao > 1"
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].triagemPreEclampsia
                    ? 'text--disabled'
                    : ''
                "
              >
                <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'A triagem combinada para pré-eclâmpsia para uma única gestação não é aplicável a gestações múltiplas no momento presente. Dado que a gestação múltipla é considerada um fator de risco para pré-eclâmpsia (especialmente pré- eclâmpsia de inicio tardio - critério epidemiológico maior), a profilaxia com AAS 150mg/24h em todas as gestações múltiplas devem ser iniciados entre 12-16 semanas até a semana 36',
                  ]"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].triagemPreEclampsia"
                  required
                  dense
                  v-model="laudo.triagemPreEclampsia"
                >
                </v-select>
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].triagemPreEclampsia"
                  @click="camposExibidos[index].triagemPreEclampsia = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].triagemPreEclampsia"
                  @click="camposExibidos[index].triagemPreEclampsia = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col> -->
              <!-- <v-col
                v-if="preEclampsia"
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].triagemPreEclampsia
                    ? 'text--disabled'
                    : ''
                "
              >
                <v-text
                  :class="
                    !camposExibidos[index].triagemPreEclampsia
                      ? 'text--disabled'
                      : ''
                  "
                  >A triagem combinada para pré-eclâmpsia para uma única
                  gestação não é aplicável a gestações múltiplas no momento
                  presente. Dado que a gestação múltipla é considerada um fator
                  de risco para pré-eclâmpsia (especialmente pré- eclâmpsia de
                  inicio tardio - critério epidemiológico maior), a profilaxia
                  com AAS 150mg/24h em todas as gestações múltiplas devem ser
                  iniciados entre 12-16 semanas até a semana 36.
                </v-text>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].triagemPreEclampsia"
                  @click="camposExibidos[index].triagemPreEclampsia = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].triagemPreEclampsia"
                  @click="camposExibidos[index].triagemPreEclampsia = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col> -->

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].visibilidadeCriMed
                    ? 'text--disabled'
                    : ''
                "
              >
                <v-text
                  :class="
                    !camposExibidos[index].visibilidadeCriMed
                      ? 'text--disabled'
                      : ''
                  "
                  >A critério médico, sugerimos pesquisa do cariótipo fetal,
                  ultrassonografia morfológica entre 20 e 24 semanas e
                  ecocardiograma fetal.</v-text
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].visibilidadeCriMed"
                  @click="camposExibidos[index].visibilidadeCriMed = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].visibilidadeCriMed"
                  @click="camposExibidos[index].visibilidadeCriMed = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                v-if="perfilCervical"
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeColoUterino
                    ? 'text--disabled'
                    : ''
                "
              >
                <span>Colo uterino</span>
                <v-select
                  class="mx-2 flex-grow-0"
                  :items="coloUterinoConclusao"
                  validate-on-blur
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeColoUterino"
                  required
                  dense
                  v-model="laudo.coloUterinoConclusao"
                />

                <v-textarea v-if="laudo.coloUterinoConclusao == 'outros'" 
                    required 
                    validate-on-blur
                    class="mx-2 flex-grow-0" 
                    dense 
                    filled 
                    outlined 
                    no-resize 
                    label="Comentários adicionais"
                    v-model="laudo.coloUterinoConclusaoOutros"
                    :readonly="carregamentoDoLaudo || !podeEditar">
                    </v-textarea>
                
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeColoUterino"
                  @click="
                    camposExibidos[index].exibeColoUterino = true;
                    laudo.coloUterinoConclusao = undefined;
                    laudo.coloUterinoConclusaoOutros = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeColoUterino"
                  @click="
                    camposExibidos[index].exibeColoUterino = false;
                    laudo.coloUterinoConclusao = undefined;
                    laudo.coloUterinoConclusaoOutros = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                v-if="preEclampsia"
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeRiscoUterino
                    ? 'text--disabled'
                    : ''
                "
              >
                <span
                  :class="
                    !camposExibidos[index].exibeRiscoUterino
                      ? 'text--disabled'
                      : ''
                  "
                  >Risco:
                </span>
                <v-select
                  :items="classificacaoRisco"
                  class="mx-2 flex-grow-0"
                  :disabled="!camposExibidos[index].exibeRiscoUterino"
                  :clearable="podeEditar"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.riscoColoUterino"
                />
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeRiscoUterino"
                  @click="
                    camposExibidos[index].exibeRiscoUterino = true;
                    laudo.riscoColoUterino = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeRiscoUterino"
                  @click="
                    camposExibidos[index].exibeRiscoUterino = false;
                    laudo.riscoColoUterino = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <v-col
                v-if="preEclampsia"
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeRiscoPartoEsp
                    ? 'text--disabled'
                    : ''
                "
              >
                <span
                  :class="
                    !camposExibidos[index].exibeRiscoPartoEsp
                      ? 'text--disabled'
                      : ''
                  "
                  >Risco de parto espontâneo antes de 34 semanas, baseado na história materna: 
                </span>
                <v-text-field
                  :disabled="!camposExibidos[index].exibeRiscoPartoEsp"
                 
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  dense
                  v-model="laudo.riscoPartoEsp"
                />
                <span>% (1 em </span>
                <v-text-field
                  :disabled="!camposExibidos[index].exibeRiscoPartoEsp"
                  
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  dense
                  v-model="laudo.riscoPartoEsp2"
                />
                <span>). </span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeRiscoPartoEsp"
                  @click="
                    camposExibidos[index].exibeRiscoPartoEsp = true;
                    laudo.riscoPartoEsp = undefined;
                    laudo.riscoPartoEsp2 = undefined;                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeRiscoPartoEsp"
                  @click="
                    camposExibidos[index].exibeRiscoPartoEsp = false;
                    laudo.riscoPartoEsp = undefined;
                    laudo.riscoPartoEsp2 = undefined;  
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
            
            </v-col>

              <v-col
                v-if="preEclampsia"
                cols="12"
                class="d-flex justify-start align-center"
              >
                <span
                  :class="
                    !camposExibidos[index].exibeFrase2 ? 'text--disabled' : ''
                  "
                  >Risco aumentado para restrição de crescimento fetal.
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exibeFrase2"
                    @click="camposExibidos[index].exibeFrase2 = true"
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exibeFrase2"
                    @click="camposExibidos[index].exibeFrase2 = false"
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </span>
              </v-col>

              <v-col
                v-if="preEclampsia"
                cols="12"
                class="d-flex justify-start align-center"
              >
                <span
                  :class="
                    !camposExibidos[index].exibeFrase1 ? 'text--disabled' : ''
                  "
                  >De acordo com o Estudo ASPRE, mulheres identificadas pela
                  triagem do primeiro trimestre como de alto risco para
                  pré-eclâmpsia, devem utilizar aspirina 150mg à noite entre 11
                  - 14 e 36 semanas de gestação.
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exibeFrase1"
                    @click="camposExibidos[index].exibeFrase1 = true"
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exibeFrase1"
                    @click="camposExibidos[index].exibeFrase1 = false"
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </span>
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <v-textarea
                  class="mx-2 pt-3"
                  outlined
                  label="Comentarios adicionais:"
                  dense
                  rows="3"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  no-resize
                  required
                  validate-on-blur
                  v-model="laudo.comentariosAdcionaisFinalUm"
                />
              </v-col>

              <v-col
                cols="12"
                class="d-flex flex-column justify-start"
                :class="
                  !camposExibidos[index].exibeFetal ? 'text--disabled' : ''
                "
              >
                <h3>NOTAS</h3>
                <p>
                  A Fetal Medicine Foundation calcula o risco de trissomia de
                  acordo com o risco corrigido pela TN em:
                </p>
                <ul class="lista-quadrada">
                  <li>Baixo o risco, o risco corrigido &#60; 1:1000</li>
                  <li>
                    Risco intermediário, o risco corrigido entre ≤ 1:51 – 1:1000
                  </li>
                  <li>Risco alto, o risco corrigido entre ≤ 1:50</li>
                </ul>
                <ul class="lista-astericicuzada">
                  <li>
                    Risco corrigido baixo – avaliação morfológica entre 20 e 24
                    semanas.
                  </li>
                  <li>
                    Risco corrigido intermediário – incluir a avaliação do osso
                    nasal, do ducto venoso, da valva tricúspide e dos marcadores
                    bioquímicos, considerar a realização de NIPT.
                  </li>
                  <li>
                    Risco corrigido alto – indicação de investigação adicional
                    (biópsia de vilo corial ou amniocentese).
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="!camposExibidos[index].exibeFetal"
                      @click="camposExibidos[index].exibeFetal = true"
                      ><v-icon>mdi-eye-off</v-icon></v-btn
                    >
                    <v-btn
                      dense
                      depressed
                      text
                      icon
                      v-if="camposExibidos[index].exibeFetal"
                      @click="camposExibidos[index].exibeFetal = false"
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </li>
                </ul>
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
              >
                <span
                  :class="
                    !camposExibidos[index].notasFrase ? 'text--disabled' : ''
                  "
                  >O risco de pré-eclâmpsia foi avaliado por uma combinação de características maternas e histórico médico com medidas de pressão arterial e fluxo sanguíneo para o útero.
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].notasFrase"
                    @click="camposExibidos[index].notasFrase = true"
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].notasFrase"
                    @click="camposExibidos[index].notasFrase = false"
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </span>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-start align-center"
              >
                <span
                  :class="
                    !camposExibidos[index].notasFrase2 ? 'text--disabled' : ''
                  "
                  >O risco de trissomias foi avaliado por uma combinação de idade materna, espessura da translucência nucal fetal e frequência cardíaca fetal.
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].notasFrase2"
                    @click="camposExibidos[index].notasFrase2 = true"
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].notasFrase2"
                    @click="camposExibidos[index].notasFrase2 = false"
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </span>
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center pt-4">
                <v-textarea
                  class="mx-2"
                  outlined
                  label="Acrescentar notas:"
                  dense
                  rows="3"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  no-resize
                  required
                  validate-on-blur
                  v-model="laudo.notasFinalUm"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
      <component
        :is="modal.modalComponent"
        v-bind="{ show: modal.active }"
        @close="closeModal"
      />
    </template>
    <v-col cols="12" class="d-flex justify-start align-center pt-4">
      <v-btn
        v-if="podeEditar"
        color="primary"
        :loading="carregamentoDoLaudo"
        @click="
          () => {
            laudoId ? editarLaudo() : salvarLaudo();
          }
        "
      >
        salvar laudo
      </v-btn>

      <v-btn
        color="primary"
        v-if="temLaudo && !podeEditar"
        @click="imprimirLaudo"
        :loading="loadingImprimir"
        class="ml-5"
      >
        Imprimir
      </v-btn>

      <v-btn
        color="primary"
        v-if="!podeEditar"
        class="ml-5"
        @click="podeEditar = true"
      >
        Editar
      </v-btn>

      <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
        Cancelar
      </v-btn>
      <!-- <v-btn
                color="primary"
                class="ml-5"

                @click="abreModal()"
              >
                alerta
              </v-btn>
              <v-btn
                color="primary"
                class="ml-5"

                @click="chamaGet()"
              >
                chamaGet
              </v-btn> -->
      <v-btn
        color="primary"
        v-if="temLaudo"
        @click="adcionarConteudoEditorPersonalizado"
        class="ml-5"
      >
        {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
      </v-btn>
    </v-col>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import TNCalcs from '@/helpers/calculosTn';
import axios from 'axios';
import moment from 'moment';
import { VueEditor } from 'vue2-editor';

import { truncateSync } from 'fs';
import { parseSync } from '@babel/core';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import { title } from 'process';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    MorfologicaPrimeiroTrimestre,
    PreEclampsia,
    PerfilCervical,
    FormInfoLaudo,
  },

  props: ['exibeLaudo', 'editaLaudo'],
  data: () => ({
    tipoGemeo: [
      {
        text: 'única',
        value: 1,
      },
      {
        text: 'gemelar',
        value: 2,
      },
      {
        text: 'trigemelar',
        value: 3,
      },
      {
        text: 'quadrigemelar',
        value: 4,
      },
      {
        text: 'quíntuplo',
        value: 5,
      },
    ],
    model: {
      usuario: null,
      senha: null,
    },
    modal: {
      active: false,
      modalComponent: ModalLogin,
    },
    gestacaoText: [
      '',
      'única',
      'gemelar',
      'trigemelar',
      'quadrigemelar',
      'quíntuplo',
    ],
    mostraAlerta: false,
    clicouNoPersonalizado: false,
    conteudoEditorPersonalizado: [],
    quantidadeFolhasEditorPersonalizado: 0,

    camposExibidos: [
      {
        exibeTransNuc: true,
        exibeOssoNasal: true,
        exibeRiscoPartoEsp: true,
        exibeRiscoTriUm: true,
        exibeRiscoTriDois: true,
        exNumPlaq: true,
        exNumBol: true,
        exDiamBi: true,
        exibeCircCef: true,
        exibeCircAbd: true,
        notasFrase2: true,
        exibeFemur: true,
        exibeColoUterino: true,
        exibeColoUterino: true,
        exibeRiscoUterino: true,
        exibeRiscoTri: true,
        exibeFetal: true,
        exibeIndicacaoExame: true,
        exibeFrase1: false,
        exibeFrase2: false,
        notasFrase: false,
        freqCardFet: true,
        compNadegas: true,
        exibePlacenta: true,
        exibeGest: true,
        visibilidadeCriMed: false,
        triagemPreEclampsia: true,
        exibeDiasDum: true,
        exibeDataMensUm: true,
        exibeSemMens: true,
        exibeDataMens: true,
        exibeDataParto: true,
        exibeTransdutor: true,
        exibeRisco: true,
        exCol: true,
        exAvCol: true,
        exOrCerIn: true,
        exProtBol: true,
        exSinalSlud: true,
        exPolipo: true,
        exibeDucto: true,
        exibeMediaIP: true,
        exibeArtUtDir: true,
        exibeArtUtEsq: true,
        exibePercentil: true,
        exibeRiscBasal: true,
        exibeRiscCorrigido: true,
        exibeFluxoTri: true,
        exibePesoPriTri: true,
        exibeCor: true,
        exibeCranPriTri: true,
        exibeSisNervPriTri: true,
        exibeTransIntraPriTri: true,
        exibeOrbPriTri: true,
        exibePerFetalPriTri: true,
        exibeEstPriTri: true,
        exibeRiscRestricao: true,
        exibeParAbdPriTri: true,
        exibeCordUmbPriTri: true,
        exibeMembSupPriTri: true,
        exibeMembInfPriTri: true,
        exibeArtRenInfPriTri: true,
        exibeBexPriTri: true,
        exibeMorfFetIdade: true,
        exibeGestTres: true,
        exibeGestQuatro: true,
        exibeDataMensDum: true,
        exibeGestCinco: true,
        exibeCordUmbInsNa: true,
        exDataNascimento: true,
        exColRep: true,
        exibeSemMens2: true,
        exibeDiasDum2: true,
      },
    ],
    loadingImprimir: false,

    coloUterinoConclusao: [
      'de aspecto ecográfico habitual para a idade gestacional',
      'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
      'afunilado, com menos de 2,5 cm de comprimento',
      'outros'
    ],
    classificacaoRisco: [
      'A gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      'A gestante foi classificada como alto risco para desenvolver pré-eclâmpsia tardia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      'A gestante foi classificada como risco aumentado para desenvolver pré-eclâmpsia precoce (risco de pré-eclâmpsia antes de 34 semanas ≥ 1:100)',
    ],
    nomeDosExames: [],
    carregamentoDoLaudo: false,
    podeEditar: true,
    laudoId: null,
    mediaIP: 0,
  }),
  watch: {
    'infosCompartilhadas.gestacao'(value) {
      const quantidadeDeLaudos = this.laudos.length;
      if (value === quantidadeDeLaudos) return;

      const diff = value - quantidadeDeLaudos;

      const novaQuantidade = [...Array(diff > 0 ? diff : diff * -1).keys()];

      if (diff > 0) novaQuantidade.forEach(() => this.laudos.push({}));
      else novaQuantidade.forEach(() => this.laudos.pop());
    },
    'infosCompartilhadas.dataUltimaMestrucao'(dataUltimaMestrucao) {
      this.$store.commit('Laudo/SET_INFO_COMPARTILHADAS', {
        semanasDUM:
          TNCalcs.idadeGestacionalMenstrualSemanas(dataUltimaMestrucao),
        diasDUM: TNCalcs.idadeGestacionalMenstrualDias(dataUltimaMestrucao),
      });
    },
  },
  computed: {
    title() {
      let titulos = ['TRANSLUCÊNCIA NUCAL'];

      if (typeof this.$route.query.variacoes === 'object') {
        titulos = [...titulos, ...this.$route.query.variacoes];
      } else if (!!this.$route.query.variacoes) {
        titulos.push(this.$route.query.variacoes);
      }

      titulos = titulos.map(titulo => titulo.toUpperCase());

      // ['TN', 'VAR1', 'VAR2'].join(' - ') -> TN - VAR1 - VAR2
      this.nomeDosExames = titulos;
      return titulos.join(' - ');
    },
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    infosCompartilhadas() {
      return this.$store.state.Laudo.infosCompartilhadas;
    },
    laudos() {
      return this.$store.state.Laudo.laudos;
    },
    morfologicaPrimeiroTrimestre() {
      return this.$route.query.variacoes.includes(
        'avaliação morfológica do primeiro trimestre'
      );
    },
    preEclampsia() {
      return this.$route.query.variacoes.includes('doppler');
    },
    temLaudo() {
      this.laudoId = this.$store.getters['Laudo/laudoId'];
      return !!this.$store.getters['Laudo/laudoId'];
    },
    perfilCervical() {
      return this.$route.query.variacoes.includes('perfil cervical');
    },
    loading() {
      return this.$store.getters['Laudo/loading'];
    },
    mediaIP() {
      return (this.mediaIP =
        parseFloat(laudo.arteriaUterinaEsquerda) +
        parseFloat(laudo.arteriaUterinaDireita) / 2.0).tofloat(
        mediaIP.toFixed(2).replace('.', ',')
      );
    },
  },
  methods: {
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudos[0].folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },
    ...mapActions('Laudo', ['getLaudos']),
    ...mapActions('Auth', ['login']),
    abreModal() {
      this.modal.active = true;
    },
    async chamaGet() {
      let resp = await axios.get(`${this.urlBase}Convenio`, {
        headers: this.headers,
      });
      return resp.status;
    },
    async getVisibilidade(val) {
      if (val) {
        return 'black';
      } else {
        return 'grey lighten-1';
      }
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudos[0].folhas) {
        this.conteudoEditorPersonalizado = this.laudos[0].folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }
      let arrayDeFetos = [];
      let todos = [];
     
      for (let i = 0; i < this.laudos.length; i++) {
        todos.push(`\n<h4>História materna </h4>`)
        if (this.laudos[i].dataNascimento)
        todos.push(`Data de nascimento : ${this.dataFormatada(this.laudos[i].dataNascimento)}.\n`);
        if (this.laudos[i].anos || this.laudos[i].meses)
        todos.push(`${this.laudos[i].anos?this.laudos[i].anos + (this.laudos[i].anos > 1 ? ' anos':' ano'):''}${this.laudos[i].anos && this.laudos[i].meses? ' e ':''}${this.laudos[i].meses?this.laudos[i].meses + (this.laudos[i].meses > 1 ? ' meses':' mês'):''}.\n`);





        if (this.laudos[i].peso) {
          todos.push(`${this.laudos[i].peso?"Peso: " + this.laudos[i].peso + ' kg.':""}${
      this.laudos[i].altura?" Altura: " + this.laudos[i].altura + ' cm.':""}${
      this.laudos[i].imc?" IMC: " + this.laudos[i].imc :""}.\n`);
        }
        if (this.laudos[i].origemRacial)
        todos.push(`Origem racial: ${this.laudos[i].origemRacial}.\n`);
        if (this.laudos[i].metodoConcepcao)
        todos.push(`Método de concepção: ${this.laudos[i].metodoConcepcao}.\n`);
        if (this.laudos[i].fuma)
        todos.push(`Fumar durante a gravidez: ${this.laudos[i].fuma}.\n`);
        if (this.laudos[i].hipertensao)
        todos.push(`Hipertensão crônica: ${this.laudos[i].hipertensao}.\n`);
        if (this.laudos[i].diabetes)
        todos.push(`Diabetes : ${this.laudos[i].diabetes}${
      this.laudos[i].diabetes == 'Sim'? ', '+ this.laudos[i].tipoDiabetes :''}.\n`);
        if (this.laudos[i].diabeteTipoUm)
        todos.push(`Diabetes tipo I: ${this.laudos[i].diabeteTipoUm}.\n`);
        if (this.laudos[i].diabeteTipoDois)
        todos.push(`Diabetes tipo II: ${this.laudos[i].diabeteTipoDois}.\n`);
        if (this.laudos[i].diabeteTipoGestacional)
        todos.push(`Diabetes gestacional: ${this.laudos[i].diabeteTipoGestacional}.\n`);
        if (this.laudos[i].lupus)
        todos.push(`Lúpus eritematoso sistêmico:  ${this.laudos[i].lupus}.\n`);
        if (this.laudos[i].lupus)
        todos.push(`Síndrome antifosfolipídica:  ${this.laudos[i].sindromeAnti}.\n`);
        if (this.laudos[i].historicoFamiliarDiabetes)
        todos.push(`História familiar de diabetes:  ${this.laudos[i].historicoFamiliarDiabetes}.\n`);
        if(this.laudos[i].historiaObstetricaAnterior){

        
        if(this.laudos[i].historiaObstetricaAnterior.length>0)
        todos.push("\n<h4>História obstétrica anterior</h4>")
        for (let j = 0; j < this.laudos[i].historiaObstetricaAnterior.length; j++) {
          todos.push(this.laudos[i].historiaObstetricaAnterior[j]+ '.\n');
        }
      }
      if(this.laudos[i].perdasGestacionais){

      
        if(this.laudos[i].perdasGestacionais.length>0)
        todos.push("\n<h4>Perdas gestacionais</h4>")
        for (let k = 0; k < this.laudos[i].perdasGestacionais.length; k++) {
          todos.push(this.laudos[i].perdasGestacionais[k] + '.\n');
         
        }
      }
        
        
        
        if (this.laudos[i].indicacaoExame) {
          todos.push(`Indicação do exame ${this.laudos[i].indicacaoExame}.\n`);
        }
        if (this.laudos[i].transdutor) {
          todos.push(
            `Exame realizado com transdutor ${this.infosCompartilhadas.transdutor} de alta resolução.\n`
          );
        }
        if (this.infosCompartilhadas.dataUltimaMestrucao) {
          todos.push(
            `Data da última menstruação ${this.dataFormatada(
              this.infosCompartilhadas.dataUltimaMestrucao
            )}.\n`
          );
        }
        if (this.infosCompartilhadas.dataUltimaMestrucao) {
          todos.push(
            `Idade gestacional (DUM) ${this.infosCompartilhadas.semanasDUM} semanas e ${this.infosCompartilhadas.diasDUM} dias.\n`
          );
        }
        if (this.infosCompartilhadas.gestacao) {
          todos.push(
            `\n<strong>Gestação ${this.gestacaoText[this.infosCompartilhadas.gestacao]}</strong>`
          );
        }
        if (this.laudos[i].placenta)
        todos.push(`Placenta ${this.laudos[i].placenta}.\n`);
        if (this.laudos[i].comprimentoCabecaNadegas) {
          todos.push(
            `Comprimento cabeça-nádegas: ${this.laudos[i].comprimentoCabecaNadegas} mm.\n`
          );
        }
        if (this.laudos[i].frequenciaCardiacaFetal) {
          todos.push(
            `Frequência cardíaca fetal: ${this.laudos[i].frequenciaCardiacaFetal} bpm.\n`
          );
        }
        
        // todos.push('\n');
        if (this.laudos.length > 1 ) {
          todos.push('\n<h3>Corionicidade</h3>');
          if (this.laudos[i].numPlacentas) {
            todos.push(
              `Número de Placentas: ${this.laudos[i].numPlacentas}.\n`
            );
          }
          if (this.laudos[i].numBolsasAmni) {
            todos.push(
              `Número de bolsas amnióticas: ${this.laudos[i].numBolsasAmni}.\n`
            );
          }
        }
        if (this.laudos.length > 1) {
          todos.push(`\n<h4>Feto ${i + 1} </h4>`);
        }
        

        todos.push(`\n<h4>Morfologia fetal </h4>`);
        // todos.push('\n<h4>Morfologia fetal</h4>');
        if (this.laudos[i].diametroBiparietalPrimeiroTrimestre) {
          todos.push(
            `Diâmetro biparietal: ${this.laudos[i].diametroBiparietalPrimeiroTrimestre}.\n`
          );
        }
        if (this.laudos[i].circunferenciaCefalicaPrimeiroTrimestre) {
          todos.push(
            `Circunferência cefálica: ${this.laudos[i].circunferenciaCefalicaPrimeiroTrimestre} mm.\n`
          );
        }
        if (this.laudos[i].circunferenciaAbdominalPrimeiroTrimestre) {
          todos.push(
            `Circunferência abdominal: ${this.laudos[i].circunferenciaAbdominalPrimeiroTrimestre} mm.\n`
          );
        }
        if (this.laudos[i].femurPrimeiroTrimestre) {
          todos.push(`Fêmur: ${this.laudos[i].femurPrimeiroTrimestre} mm.\n`);
        }
        if (this.laudos[i].pesoPrimeiroTrimestre) {
          todos.push(`Peso ${this.laudos[i].pesoPrimeiroTrimestre} g.\n`);
        }
        if (this.laudos[i].cranioPrimeiroTrimestre) {
          todos.push(`Crânio ${this.laudos[i].cranioPrimeiroTrimestre === 'outros' ? this.laudos[i].comentariosAdcionaisCranio : this.laudos[i].cranioPrimeiroTrimestre}.\n`);

        }
        if (this.laudos[i].sistemaNervosoCentralPrimeiroTrimistre) {
          todos.push(
            `Sistema nervoso central ${this.laudos[i].sistemaNervosoCentralPrimeiroTrimistre === 'outros' ? this.laudos[i].sistemaNervosoCentralPrimeiroTrimistreOutros : this.laudos[i].sistemaNervosoCentralPrimeiroTrimistre}.\n`
          );
        }
        if (this.laudos[i].translucenciaInstracranianaPrimeiroTrimistre) {
          todos.push(
            `Translucência intracraniana ${
              this.laudos[i].translucenciaInstracranianaPrimeiroTrimistre
            }${
              this.laudos[i].translucenciaInstracranianaPrimeiroTrimistre ===
                'presente medindo' &&
              this.laudos[i].translucenciaInstracranianaPrimeiroTrimistreMedindo
                ? ` ${this.laudos[i].translucenciaInstracranianaPrimeiroTrimistreMedindo} mm`
                : ''
            }`
          );
          todos.push('.\n');
        }
        if (this.laudos[i].orbitasPrimeiroTrimistre) {
          todos.push(`Órbitas ${this.laudos[i].orbitasPrimeiroTrimistre}.\n`);
        }
        if (this.laudos[i].perfilFetalPrimeiroTrimistre) {
          todos.push(
            `Perfil fetal ${this.laudos[i].perfilFetalPrimeiroTrimistre === 'outros' ? this.laudos[i].perfilFetalPrimeiroTrimistreOutros : this.laudos[i].perfilFetalPrimeiroTrimistre}.\n`
          );
        }
        if (this.laudos[i].estomagoPrimeiroTrimistre) {
          todos.push(`Estômago ${this.laudos[i].estomagoPrimeiroTrimistre}.\n`);
        }
        if (this.laudos[i].coracaoCampo)
        todos.push(`Coração: ${this.laudos[i].coracaoCampo}.`);
        if (this.laudos[i].paredeAbdominalPrimeiroTrimistre) {
          todos.push(
            `Parede abdominal ${this.laudos[i].paredeAbdominalPrimeiroTrimistre === 'outros' ? this.laudos[i].paredeAbdominalPrimeiroTrimistreOutros : this.laudos[i].paredeAbdominalPrimeiroTrimistre}.\n`
          );
        }
        if (this.laudos[i].bexigaPrimeiroTrimistre) {
          todos.push(`Bexiga ${this.laudos[i].bexigaPrimeiroTrimistre === 'outros' ? this.laudos[i].bexigaPrimeiroTrimistreOutros : this.laudos[i].bexigaPrimeiroTrimistre}.\n`);
        }
        
        if (this.laudos[i].cordaoUmbilicalPrimeiroTrimistre) {
          todos.push(
            `Cordão umbilical ${
              this.laudos[i].cordaoUmbilicalPrimeiroTrimistre
            }, ${
              this.laudos[i].cordaoUmbilicalInseridoNa
                ? this.laudos[i].cordaoUmbilicalInseridoNa
                : ''
            }`
          );
          todos.push('.\n');
        }

        if (this.laudos[i].membrosSuperioresPrimeiroTrimistre) {
          todos.push(
            `Membros superiores ${this.laudos[i].membrosSuperioresPrimeiroTrimistre}.\n`
          );
        }
        if (this.laudos[i].membrosInferioresPrimeiroTrimistre) {
          todos.push(
            `Membros inferiores ${this.laudos[i].membrosInferioresPrimeiroTrimistre}.\n`
          );
        }
        if (this.laudos[i].artereiasRenaisPrimeiroTrimistre) {
          todos.push(
            `Artérias renais ${this.laudos[i].artereiasRenaisPrimeiroTrimistre}.\n`
          );
        }
        todos.push('\n<h4>Marcadores Para Cromossomopatias</h4>');
        if (this.laudos[i].transulucenciaNucal) {
          todos.push(
            `Translucência nucal ${this.laudos[i].transulucenciaNucal} mm.\n`
          );
        }
        if (this.laudos[i].ossoNasal) {
          todos.push(`Osso nasal ${this.laudos[i].ossoNasal}.\n`);
        }
        if (this.laudos[i].fluxoTriscupide) {
          todos.push(`Fluxo Tricúspide ${this.laudos[i].fluxoTriscupide}.\n`);
        }
        if (this.laudos[i].ductoVenoso) {
          todos.push(`Ducto venoso ${this.laudos[i].ductoVenoso}.\n`);
        }
        
        
       
        todos.push('\n<h4>Risco Para Trissomias</h4>');

        if (this.laudos[i].riscoTrissomiaum) {
          todos.push('Risco basal (baseado na idade materna):');
          todos.push(
            `<span>Trissomia do 21 - 1: <Strong>${this.laudos[i].riscoTrissomiaum}.</Strong></span>`
          );
        }
        if (this.laudos[i].riscoTrissomiadois) {
          todos.push('Risco corrigido (baseado na idade materna, TN e FCP):');
          todos.push(
            `<span>Trissomia do 21 - 1: <Strong>${this.laudos[i].riscoTrissomiadois}.</Strong></span>`
          );
        }
        todos.push('\n<h4>Risco para pré-eclâmpsia</h4>');
        if (this.laudos[i].arteriaUterinaDireita) {
          todos.push('Artéria uterina direita:\n');
          todos.push(`IP: ${this.laudos[i].arteriaUterinaDireita}.\n`);
        }
        if (this.laudos[i].arteriaUterinaEsquerda) {
          todos.push('Artéria uterina esquerda:\n');
          todos.push(`IP: ${this.laudos[i].arteriaUterinaEsquerda}.\n`);
        }
        if (this.laudos[i].arteriaUterinaEsquerda) {
          todos.push('');

          const mediaIP = parseFloat(
            (parseFloat(this.laudos[i].arteriaUterinaEsquerda) +
              parseFloat(this.laudos[i].arteriaUterinaDireita)) /
              2.0
          ).toFixed(2);
          const percentil = this.laudos[i].percentil
            ? this.laudos[i].percentil
            : '';

          todos.push(
            `Média do IP: ${mediaIP} MoM${
              percentil ? ' ' + percentil : ''
            }.\n`
          );
        }

        if (this.laudos[i].riscoBasalPreEclampsia) {
          todos.push(
            `<span>Risco para pré-eclâmpsia precoce - 1: <Strong>${this.laudos[i].riscoBasalPreEclampsia}.</Strong></span>`
          );
        }
        if (this.laudos[i].riscoCorrigidoPreEclampsia) {
          todos.push(
            `<span>Risco para pré-eclampsia tardia - 1: <Strong>${this.laudos[i].riscoCorrigidoPreEclampsia}.</Strong></span>`
          );
        }
        if (this.laudos[i].riscoRestricaoCrescimento )
        todos.push(
          `Risco de restrição de crescimento fetal antes de 37 semana – 1: ${this.laudos[i].riscoRestricaoCrescimento}.\n`
        );
        if (this.$route.query.variacoes.includes('perfil cervical')) {
          todos.push('\n<h4>Perfil Cervical</h4>');
        }

        if (this.laudos[i].coloUterino) {
          todos.push(
            `<span>Colo uterino ${this.laudos[i].coloUterino}${
              this.laudos[i].coloMedidaEmRepouco
                ? ', com menor comprimento de <strong>' +
                  this.laudos[i].coloMedidaEmRepouco +
                  '</strong> mm (colo avaliado por três minutos)'
                : ''
            }.</span>`
          );
          
        }

        // if (this.laudos[i].coloPassouAMedir) {
        //   todos.push(
        //     `<span>Após três minutos de avaliação, o colo ${
        //       this.laudos[i].coloPassouAMedir
        //     } <strong>${this.laudos[i].cm ? this.laudos[i].cm + '</strong> cm.</span>' : ''}`
        //   );
         
        // }
      
        if (this.laudos[i].orificioCervicalInterno) {
          todos.push(
            `<span>Orifício cervical interno ${
              this.laudos[i].orificioCervicalInterno
            }${
              this.laudos[i].orificioCervicalInterno === 'aberto para'
                ? ` ${
                    this.laudos[i].orificioCervicalInternoDois
                      ? this.laudos[i].orificioCervicalInternoDois
                      : ''
                  }  mm`
                : ''
            }.</span>`
          );
        }
        if (this.laudos[i].polipoEndocervicalMedindo) {
          todos.push(
            `<span>${this.laudos[i].polipoEndocervicalMedindo}${
              this.laudos[i].polipoEndocervicalMedindo ===
              'Observamos pólipo endocervical medindo'
                ? `<strong> ${
                    this.laudos[i].polipoEndocervicalMedindoCm
                      ? this.laudos[i].polipoEndocervicalMedindoCm
                      : ''
                  }</strong> mm`
                : ''
            }.</span>`
          );
        }
        
        if (this.laudos[i].protusaoBolsaAmniotica) {
          todos.push(
            `${this.laudos[i].protusaoBolsaAmniotica} de protrusão da bolsa amniótica no momento do exame.\n`
          );
        }
        if (this.laudos[i].sinalDeSludge) {
          todos.push(`Sinal de sludge ${this.laudos[i].sinalDeSludge}.\n`);
        }
        
        

        todos.push('\n<h4>Conclusão</h4>');
        if (this.infosCompartilhadas.gestacao) {
          todos.push(
            `Gestação ${this.gestacaoText[this.infosCompartilhadas.gestacao]}${
              this.infosCompartilhadas.gestacao === 2
                ? this.laudos[i].gestacaoTres +
                  ', ' +
                  this.laudos[i].gestacaoQuatro
                : ''
            }${
              [3, 4, 5].includes(this.infosCompartilhadas.gestacao)
                ? (this.laudos[i].gestacaoCinco? this.laudos[i].gestacaoCinco : '')
                : ''
            } com feto vivo.\n`
          );
        }
        // if (this.laudos.length == 1)
        //   todos.push(`Gestação única com feto vivo.\n`);
        // if (this.laudos.length == 2)
        //   todos.push(`Gestação gemelar com feto vivo.\n`);
        // if (this.laudos.length == 3)
        //   todos.push(`Gestação trigemelar com feto vivo.\n`);
        // if (this.laudos.length == 4)
        //   todos.push(`Gestação quadrigemelar com feto vivo.\n`);
        // if (this.laudos.length == 5)
        //   todos.push(`Gestação quintupla com feto vivo.\n`);

        if (this.laudos[i].semanasDUM2)
          todos.push(
            `<span>Idade gestacional baseada na biometria fetal atual de  <strong>${
              this.laudos[i].semanasDUM2 ? this.laudos[i].semanasDUM2 : ''
            }${
              this.laudos[i].semanasDUM2
                ? this.laudos[i].semanasDUM2 > 1
                  ? ' semanas'
                  : ' semana '
                : ''
            }${
              this.laudos[i].semanasDUM2 && this.laudos[i].diasDUM2 ? ' e ' : ''
            }${this.laudos[i].diasDUM2 ? this.laudos[i].diasDUM2 : ''}${
              this.laudos[i].diasDUM2
                ? this.laudos[i].diasDUM2 > 1
                  ? ' dias'
                  : ' dia'
                : ''
            }</strong>${
              this.laudos[i].examServico
                ? ', baseada na ultrassonografia de primeiro trimestre ' + this.laudos[i].examServico
                : ''
            }${
              this.laudos[i].compativelIncompativel
                ? ', ' + this.laudos[i].compativelIncompativel
                : ''
            }${
              this.laudos[i].compativelIncompativel
                ? ' com a biometria atual'
                : ''
            }.</span>`
          );
        if (!this.laudos[i].incerta)
          if (!this.laudos[i].incerta)
            if (this.laudos[i].dataProvavelParto)
              todos.push(
                `<span>Data provável do parto: <strong>${this.dataFormatada(
                  this.laudos[i].dataProvavelParto
                )}</strong>.</span>`
              );

        if (this.laudos[i].riscoTrissomia) {
          const taxaRisco = this.laudos[i].taxaRisco
            ? this.laudos[i].taxaRisco
            : '';

          todos.push(
            `<span>O risco de trissomia do 13, 18 e 21 <strong>${this.laudos[i].riscoTrissomia}</strong> após a medida da TN no feto avaliado, sendo considerado de <strong>${taxaRisco}</strong> para cromossomopatias.</span>`
          );
        }

        // if (this.laudos[i].morfologiafetalIdadeGestacional) {
        //   todos.push(
        //     `${this.laudos[i].morfologiafetalIdadeGestacional}${
        //       this.laudos[i].morfologiafetalIdadeGestacional ===
        //       'Alterações precoces da morfologia fetal, caracterizada por'
        //         ? ` ${
        //             this.laudos[i].morfologiafetalIdadeGestacionaUmOutros
        //               ? this.laudos[i].morfologiafetalIdadeGestacionaUmOutros
        //               : ''
        //           }`
        //         : ''
        //     }.\n`
        //   );
        // }

        if (
          this.laudos[i].morfologiafetalIdadeGestacional &&
          this.laudos[i].morfologiafetalIdadeGestacional !=
            'Alterações precoces da mofologia fetal, caracterizada por'
        )
          todos.push(`${this.laudos[i].morfologiafetalIdadeGestacional}.\n`);
        if (
          this.laudos[i].morfologiafetalIdadeGestacional &&
          this.laudos[i].morfologiafetalIdadeGestacional ===
            'Alterações precoces da mofologia fetal, caracterizada por'
        )
          todos.push(
            `${this.laudos[i].morfologiafetalIdadeGestacional} ${this.laudos[i].morfologiafetalIdadeGestacionaUmOutros}\n`
          );

        if (this.laudos[i].conclusaoTres)
          todos.push(`${this.laudos[i].conclusaoTres}\n`);
        if (this.laudos[i].coloUterinoConclusao)
          todos.push(`Colo uterino ${this.laudos[i].coloUterinoConclusao === 'outros' ? this.laudos[i].coloUterinoConclusaoOutros : this.laudos[i].coloUterinoConclusao}.\n`);

        

        if (this.laudos[i].camposExibidos.triagemPreEclampsia == true)
          todos.push(
            'A triagem combinada para pré-eclâmpsia para uma única gestação não é aplicável a gestações múltiplas no momento presente. Dado que a gestação múltipla é considerada um fator de risco para pré-eclâmpsia (especialmente pré- eclâmpsia de inicio tardio - critério epidemiológico maior), a profilaxia com AAS 150mg/24h em todas as gestações múltiplas devem ser iniciados entre 12-16 semanas até a semana 36.\n'
          );
        if (this.laudos[i].camposExibidos.visibilidadeCriMed == true)
          todos.push(
            'A critério médico, sugerimos pesquisa do cariótipo fetal, ultrassonografia morfológica entre 20 e 24 semanas e ecocardiograma fetal.\n'
          );

        if (
          this.laudos[i].riscoColoUterino ===
          'A gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)'
        )
          todos.push(
            `<span>A gestante foi classificada como <strong>baixo risco</strong> para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100).</span>`
          );
        else if (
          this.laudos[i].riscoColoUterino ===
          'A gestante foi classificada como alto risco para desenvolver pré-eclâmpsia tardia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)'
        )
          todos.push(
            `<span>A gestante foi classificada como <strong>alto risco</strong> para desenvolver pré-eclâmpsia tardia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100).</span>`
          );
        else if (
          this.laudos[i].riscoColoUterino ===
          'A gestante foi classificada como alto risco para desenvolver pré-eclâmpsia precoce (risco de pré-eclâmpsia antes de 34 semanas ≥ 1:100)'
        )
          todos.push(
            `<span>A gestante foi classificada como <strong>alto risco</strong> para desenvolver pré-eclâmpsia precoce (risco de pré-eclâmpsia antes de 34 semanas ≥ 1:100).</span>`
          );
          else if (this.laudos[i].riscoColoUterino)
          todos.push(`${this.laudos[i].riscoColoUterino}.\n`);

          if (
        this.laudos[0].riscoPartoEsp
       
      )
        todos.push(`Risco de parto espontâneo antes de 34 semanas, baseado na história materna: ${this.laudos[0].riscoPartoEsp} % (1 em ${this.laudos[0].riscoPartoEsp2}).\n`);

        if (this.laudos[i].camposExibidos.exibeFrase2 === true)
          todos.push(
            `Risco aumentado para restrição de crescimento fetal.\n`
          );
        if (this.laudos[i].camposExibidos.exibeFrase1 === true)
          todos.push(
            `De acordo com o Estudo ASPRE, mulheres identificadas pela triagem do primeiro trimestre como de alto risco para pré-eclâmpsia, devem utilizar aspirina 150mg à noite entre 11 - 14 e 36 semanas de gestação.\n`
          );
        
        if (this.laudos[i].comentariosAdcionaisFinalUm)
          todos.push(`${this.laudos[i].comentariosAdcionaisFinalUm}\n`);
          todos.push('\n<h4>NOTAS</h4>');
        if (this.laudos[i].camposExibidos.exibeFetal === true && this.getLenght(this.laudos) - 1 == i )
          todos.push(
            ` A Fetal Medicine Foundation calcula o risco de trissomia em:\n`
          );
        if (this.laudos[i].camposExibidos.exibeFetal === true && this.getLenght(this.laudos) - 1 == i)
          todos.push(`■ Baixo risco, o risco corrigido < 1:1000\n`);
        if (this.laudos[i].camposExibidos.exibeFetal === true && this.getLenght(this.laudos) - 1 == i)
          todos.push(
            `■ Risco intermediário, o risco corrigido entre 1:51 - 1:1000\n`
          );
        if (this.laudos[i].camposExibidos.exibeFetal === true && this.getLenght(this.laudos) - 1 == i)
          todos.push(`■ Risco alto, o risco corrigido ≤ 1:50\n`);

        if (this.laudos[i].camposExibidos.exibeFetal === true && this.getLenght(this.laudos) - 1 == i)
          todos.push(
            `* Risco corrigido baixo - avaliação morfológica entre 20 e 24 semanas.\n`
          );
        if (this.laudos[i].camposExibidos.exibeFetal === true && this.getLenght(this.laudos) - 1 == i)
          todos.push(
            `* Risco corrigido intermediário - incluir a avaliação do osso nasal, do ducto venoso, da valva tricúspide e dos marcadores bioquímicos. Considerar a realização de NIPT.\n`
          );
        if (this.laudos[i].camposExibidos.exibeFetal === true && this.getLenght(this.laudos) - 1 == i)
          todos.push(
            `* Risco corrigido alto – indicação de investigação adicional (biópsia de vilo corial ou amniocentese).\n`
          );

        if (this.laudos[i].camposExibidos.notasFrase === true && this.getLenght(this.laudos) - 1 == i)
          todos.push(
            `O risco de pré-eclâmpsia foi avaliado por uma combinação de características maternas e histórico médico com medidas de pressão arterial e fluxo sanguíneo para o útero.\n`
          );
          if (this.laudos[i].camposExibidos.notasFrase2 === true )
          todos.push(`O risco de trissomias foi avaliado por uma combinação de idade materna, espessura da translucência nucal fetal e frequência cardíaca fetal.\n`
          );

        if (this.laudos[i].notasFinalUm)
          todos.push(`${this.laudos[i].notasFinalUm}\n`);
        arrayDeFetos[i] = todos;
      }

      while (todos.length > 0) {
        const teste = todos.splice(0, 34);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }
      

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },
    getLenght(objeto){
      
      const tamanho = Object.keys(objeto).length;
 
      return tamanho

    },
    calculaImc(){
      
      
      if(!this.laudos[0].altura || !this.laudos[0].peso) {
        return}
      let altura = this.laudos[0].altura
      let peso = this.laudos[0].peso
      altura = altura / 100; // converter para metros

    // Calculando o IMC
      const imc = peso / (altura * altura);
      this.laudos[0].imc = imc.toFixed(2)
      
      this.$forceUpdate()

    },
    titulo() {
      let titulos = ['TRANSLUCÊNCIA NUCAL'];

      if (typeof this.$route.query.variacoes === 'object') {
        titulos = [...titulos, ...this.$route.query.variacoes];
      } else if (!!this.$route.query.variacoes) {
        titulos.push(this.$route.query.variacoes);
      }

      titulos = titulos.map(titulo => titulo.toUpperCase());

      // ['TN', 'VAR1', 'VAR2'].join(' - ') -> TN - VAR1 - VAR2
      this.nomeDosExames = titulos;
      return titulos.join(' - ');
    },
    async fazLogin() {
      let dados = await this.login(this.model);

      this.mostraAlerta = false;
    },
    closeModal() {
      this.modal.active = false;
    },
    calcularIdadeEMeses() {
    const dataNascimento = moment(this.laudos[0].dataNascimento);
    const hoje = moment();
    

    const anos = hoje.diff(dataNascimento, 'years');
    dataNascimento.add(anos, 'years');
    const meses = hoje.diff(dataNascimento, 'months');
    
      this.laudos[0].anos = anos
      this.laudos[0].meses = meses
      
      this.$forceUpdate()
    
  },
    async salvarLaudo() {
      this.formataLaudo();
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        return;
      } finally {
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            this.$store.commit('Laudo/SET_LOADING', true);
            this.$store.commit(
              'Laudo/SET_CAMPOS_EXIBIDOS',
              this.camposExibidos
            );
            const dados = await this.$store.dispatch(
              'Laudo/postLaudos',
              this.$route.query.exame
            );

            await this.$store.dispatch('Consulta/patchConsulta', {
              consultaID: this.$route.query.consulta,
              path: 'status',
              value: 'LAUDADO',
            });
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: async (store, exame, consulta) => {
                await store.dispatch('Laudo/postLaudos', exame);
                await store.dispatch('Consulta/patchConsulta', {
                  consultaID: consulta,
                  path: 'status',
                  value: 'LAUDADO',
                });
              },
              exame: this.$route.query.exame,
              consulta: this.$route.query.consulta,
              codigo: 'POST_LAUDO_TN',
            });
            this.$store.commit('offline/SET_DADOS', this.laudos);
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.$store.commit('Laudo/SET_LOADING', false);
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudos[0].folhas = this.conteudoEditorPersonalizado;
      this.laudos[0].titulo = this.titulo;
    },
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      this.formataLaudo();

      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;
        return;
      } finally {
        this.carregamentoDoLaudo = false;
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            this.$store.commit(
              'Laudo/SET_CAMPOS_EXIBIDOS',
              this.camposExibidos
            );
            await this.$store.dispatch('Laudo/pathLaudos');
            const dados = this.patchLaudoTn(this.laudoId, this.laudos);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoTnOffline.bind({}),
              laudoId: this.laudoId,
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO_TN',
            });
            this.$store.commit('offline/SET_DADOS', this.laudos);
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
          console.error(error);
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      this.loadingImprimir = true;
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.translucencianucal',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },
    formataLaudo() {
      if (
        this.laudos[0].dataUltimaMestrucao !=
        this.infosCompartilhadas.dataUltimaMestrucao
      ) {
        this.laudos[0].dataUltimaMestrucao =
          this.infosCompartilhadas.dataUltimaMestrucao;
      }

      if (
        !!this.laudos[0].arteriaUterinaEsquerda &&
        this.laudos[0].arteriaUterinaDireita
      ) {
        this.laudos[0].mediaIP = parseFloat(
          (parseFloat(this.laudos[0].arteriaUterinaEsquerda) +
            parseFloat(this.laudos[0].arteriaUterinaDireita)) /
            2.0
        ).toFixed(1);
      }
      if (
        !!this.laudos[0].arteriaUterinaEsquerda &&
        !!this.laudos[0].arteriaUterinaDireita
      ) {
        this.laudos[0].mediaIP = parseFloat(
          (parseFloat(this.laudos[0].arteriaUterinaEsquerda) +
            parseFloat(this.laudos[0].arteriaUterinaDireita)) /
            2.0
        ).toFixed(1);
      }
      if (
        this.exibeTransdutor &&
        (!!this.infosCompartilhadas.transdutor ||
          this.infosCompartilhadas.transdutor == null)
      ) {
        this.infosCompartilhadas.transdutor = 'convexo';
      }
      if (
        this.exibeOssoNasal &&
        (this.laudos[0].ossoNasal || this.laudos[0].ossoNasal == null)
      ) {
        this.laudos[0].ossoNasal = 'presente';
      }
      if (
        this.exibeRisco &&
        (!!this.laudos[0].riscoTrissomia ||
          this.laudos[0].riscoTrissomia == null)
      ) {
        this.laudos[0].riscoTrissomia = 'diminuiu';
      }
      if (
        this.exibeRisco &&
        (!!this.laudos[0].taxaRisco || this.laudos[0].taxaRisco == null)
      ) {
        this.laudos[0].taxaRisco = 'baixo risco';
      }
      if (
        this.exibeDataMens &&
        (!!this.laudos[0].compativelIncompativel ||
          this.laudos[0].compativelIncompativel == null)
      ) {
        this.laudos[0].compativelIncompativel = 'compatível';
      }
      if (
        this.exibePlacenta &&
        (!!this.laudos[0].placenta || this.laudos[0].placenta == null)
      ) {
        this.laudos[0].placenta = 'próxima ao colo uterino';
      }
      if (this.exibeMediaIP) {
        this.laudos[0].MediaIp = parseFloat(
          (parseFloat(this.laudos[0].arteriaUterinaEsquerda) +
            parseFloat(this.laudos[0].arteriaUterinaDireita)) /
            2.0
        ).toFixed(1);
      }
      if (this.triagemPreEclampsia) {
        this.laudos[0].TriaGem = true;
      } else {
        this.laudos[0].TriaGem = false;
      }
      if (this.visibilidadeCriMed) {
        this.laudos[0].CriMed = true;
      } else {
        this.laudos[0].CriMed = false;
      }
      //alterei
      if (!this.preEclampsia) {
        this.laudos[0].MediaIp = undefined;
      }

      if (this.exibeFetal) {
        this.laudos[0].exibeFetal = true;
      } else {
        this.laudos[0].exibeFetal = false;
      }

      if (this.camposExibidos[0].exibeFrase2 && this.preEclampsia) {
        this.camposExibidos[0].exibeFrase2 = true;
      } else {
        this.camposExibidos[0].exibeFrase2 = false;
      }

      if (this.camposExibidos[0].exibeFrase1 && this.preEclampsia) {
        this.camposExibidos[0].exibeFrase1 = true;
      } else {
        this.camposExibidos[0].exibeFrase1 = false;
      }
      
      this.laudos[0].semanasDUM = this.infosCompartilhadas.semanasDUM;
      this.laudos[0].diasDUM = this.infosCompartilhadas.diasDUM;
    },
  },
  async mounted() {
    this.$store.commit('Laudo/RESET_LAUDOS');

    if (!!this.$route.query?.exame) {
      this.$store.dispatch('Laudo/getLaudos', this.$route.query.exame);
    }
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.length > 0
    ) {
      this.camposExibidos = [];
      for (
        let i = 0;
        i < this.$store.getters['laudoSelecionado'].laudoJson.length;
        i++
      ) {
        this.camposExibidos.push(
          this.$store.getters['laudoSelecionado'].laudoJson[i].camposExibidos
        );
      }
    }

    for (let i = this.camposExibidos.length; i < 5; i++) {
      this.camposExibidos.push({
        exibeTransNuc: true,
        exibeOssoNasal: true,
        exibeRiscoTriUm: true,
        exibeRiscoTriDois: true,
        exibeCor: true,
        exNumPlaq: true,
        exNumBol: true,
        exDiamBi: true,
        exibeCircCef: true,
        exibeCircAbd: true,
        notasFrase2: true,
        exibeFemur: true,
        exibeColoUterino: true,
        exibeColoUterino: true,
        exibeRiscoUterino: true,
        exibeRiscoTri: true,
        exibeFetal: true,
        exibeIndicacaoExame: true,
        exibeFrase1: false,
        exibeFrase2: false,
        notasFrase: false,
        freqCardFet: true,
        compNadegas: true,
        exibePlacenta: true,
        exibeGest: true,
        triagemPreEclampsia: true,
        visibilidadeCriMed: false,
        exibeDiasDum: true,
        exibeDataMensUm: true,
        exibeSemMens: true,
        exibeDataMens: true,
        exibeDataMensDum: true,
        exibeRiscRestricao: true,
        exibeDataParto: true,
        exibeTransdutor: true,
        exibeRisco: true,
        exCol: true,
        exAvCol: true,
        exOrCerIn: true,
        exProtBol: true,
        exSinalSlud: true,
        exPolipo: true,
        exibeDucto: true,
        exibeMediaIP: true,
        exibeArtUtDir: true,
        exibeArtUtEsq: true,
        exibePercentil: true,
        exibeRiscBasal: true,
        exibeRiscCorrigido: true,
        exibeFluxoTri: true,
        exibePesoPriTri: true,
        exibeCranPriTri: true,
        exibeSisNervPriTri: true,
        exibeTransIntraPriTri: true,
        exibeOrbPriTri: true,
        exibePerFetalPriTri: true,
        exibeEstPriTri: true,
        exibeParAbdPriTri: true,
        exibeRiscoPartoEsp: true,
        exibeCordUmbPriTri: true,
        exibeMembSupPriTri: true,
        exibeMembInfPriTri: true,
        exibeArtRenInfPriTri: true,
        exibeBexPriTri: true,
        exibeMorfFetIdade: true,
        exibeGestTres: true,
        exibeGestQuatro: true,
        exibeGestCinco: true,
        exibeCordUmbInsNa: true,
        exColRep: true,
        exibeSemMens2: true,
        exDataNascimento: true,
        exibeDiasDum2: true,
      });
    }

    if (
      this.laudoSelecionado.laudoJson[0].coloUterino &&
      !this.nomeDosExames.includes('PERFIL CERVICAL') &&
      this.consultaSelecionada.liberada === undefined
    ) {
      alert(
        "O laudo possui variações, adicione a variação 'PERFIL CERVICAL' para abrir corretamente"
      );
      const { consulta } = this.$route.query;
      return this.$router.push({ name: 'laudo', query: { consulta } });
    }
    if (
      this.laudoSelecionado.laudoJson[0].arteriaUterinaDireita &&
      !this.nomeDosExames.includes('DOPPLER') &&
      this.consultaSelecionada.liberada === undefined
    ) {
      alert(
        "O laudo possui variações, adicione a variação 'DOPPLER' para abrir corretamente"
      );
      const { consulta } = this.$route.query;
      return this.$router.push({ name: 'laudo', query: { consulta } });
    }
    if (
      this.laudoSelecionado.laudoJson[0].diametroBiparietalPrimeiroTrimestre &&
      !this.nomeDosExames.includes(
        'AVALIAÇÃO MORFOLÓGICA DO PRIMEIRO TRIMESTRE'
      ) &&
      this.consultaSelecionada.liberada === undefined
    ) {
      alert(
        "O laudo possui variações, adicione a variação 'AVALIAÇÃO MORFOLÓGICA DO PRIMEIRO TRIMESTRE' para abrir corretamente"
      );
      const { consulta } = this.$route.query;
      return this.$router.push({ name: 'laudo', query: { consulta } });
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.lista-quadrada {
  list-style-type: square !important;
}
.lista-astericicuzada {
  list-style-type: '* ' !important;
}
</style>
